import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Loader, Message } from "semantic-ui-react";

export class AthleteTrendGraph extends React.PureComponent {
  get options() {
    return {
      chart: {
        zoomType: "xy",
        height: 250,
        alignTicks: false,
      },
      title: {
        text: null,
      },
      credits: {
        enabled: false,
      },
      xAxis: [
        {
          categories: this.props.data.categories,
          crosshair: true,
        },
      ],
      yAxis: [
        {
          // Primary yAxis
          labels: {
            format: "{value}%",
            style: {
              color: "#50B432",
            },
          },
          title: null,
          max: 100,
          tickInterval: 20,
        },
        {
          // Secondary yAxis
          title: null,
          reversed: true,
          min: 0,
          labels: {
            format: "{value}''",
            style: {
              color: "#058DC7",
            },
          },
          opposite: true,
        },
      ],
      tooltip: {
        shared: true,
      },
      colors: [
        "#058DC7",
        "#50B432",
        "#ED561B",
        "#DDDF00",
        "#24CBE5",
        "#64E572",
        "#FF9655",
        "#FFF263",
        "#6AF9C4",
      ],
      plotOptions: {
        series: {
          lineWidth: 2,
        },
      },
      series: [
        {
          name: "Skiing time behind",
          type: "spline",
          yAxis: 1,
          data: this.props.data.data_ski,
          tooltip: {
            valueSuffix: "''",
          },
        },
        {
          name: "Shots accuracy",
          type: "spline",
          data: this.props.data.data_shoot,
          tooltip: {
            valueSuffix: "%",
          },
        },
      ],
    };
  }

  render() {
    if (this.props.error) {
      return (
        <Message
          negative
          className="text_center"
          content="Sorry, an error occured"
        ></Message>
      );
    }
    if (this.props.loading || this.props.data.length === 0) {
      return (
        <div className="LoaderContainer">
          <Loader active content="Loading"></Loader>
        </div>
      );
    }
    return (
      <>
        {this.props.empty_datas ? (
          <Message
            className="text_center"
            content="No data to display"
          ></Message>
        ) : (
          <div className="highcharts_content">
            <HighchartsReact highcharts={Highcharts} options={this.options} />
          </div>
        )}
      </>
    );
  }
}
