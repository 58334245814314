import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Loader, Message } from "semantic-ui-react";

export class HistoryRanking extends React.PureComponent {
  get options() {
    return {
      chart: {
        height: 320,
        type: "spline",
      },
      title: {
        text: null,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: this.props.data[0].categories,
      },
      yAxis: {
        title: {
          text: "Ranking",
        },
        labels: {
          formatter: function() {
            if (this.value !== 0) {
              return this.value;
            }
          },
        },

        /*tickPositioner: function () {
          var positions = [],
            tick = Math.floor(this.dataMin),
            increment = Math.ceil((this.dataMax - this.dataMin) / 6);

          if (this.dataMax !== null && this.dataMin !== null) {
            for (tick; tick - increment <= this.dataMax; tick += increment) {
              positions.push(tick);
            }
          }
          return positions;
        },*/
        min: 1,
        tickInterval: 5,
        reversed: true,
        gridLineWidth: 0,
        plotLines: [
          {
            value: 1,
            color: "#e8b318",
            dashStyle: "solid",
            width: 1,
            label: {
              text: "1",
              x: -20,
              y: 3,
              style: {
                fontSize: "10px",
                color: "#e8b318",
                fontWeight: "bold",
              },
            },
            zIndex: 1001,
          },
        ],
      },
      plotOptions: {
        series: {
          lineWidth: 3,
        },
      },
      /*plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            useHTML: true,
            formatter: function () {
              if (
                this.point.x ===
                  this.series.data[this.series.data.length - 1].x &&
                this.point.y === this.series.data[this.series.data.length - 1].y
              ) {
                let img =
                  '<img src="' +
                  this.series.userOptions.photo +
                  '" width="50" style="margin-bottom:50px;">';
                return img;
              }
              return "";
            },
          },
        },
      },*/
      series: this.props.data[0].series,
    };
  }

  render() {
    if (this.props.error) {
      return (
        <Message
          negative
          className="text_center"
          content="Sorry, an error occured"
        ></Message>
      );
    }
    if (this.props.loading || this.props.data.length === 0) {
      return (
        <div className="LoaderContainer">
          <Loader active content="Loading"></Loader>
        </div>
      );
    }
    return (
      <>
        {this.props.empty_datas ? (
          <Message
            className="text_center"
            content="No data to display"
          ></Message>
        ) : (
          <div className="highcharts_content">
            <HighchartsReact highcharts={Highcharts} options={this.options} />
          </div>
        )}
      </>
    );
  }
}
