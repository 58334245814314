import React from "react";
import ApiService from "../utils/apiService";
import { Segment, Dropdown } from "semantic-ui-react";
import { PercentWin } from "../components/PercentWin";
import { COUNTRY_CODES_CONVERSION } from "../utils/constants";
import axios from "axios";

const options = [
  { key: "All", text: "All Disciplines", value: "all" },
  { key: "Sprint", text: "Sprint", value: "SP" },
  { key: "Pursuit", text: "Pursuit", value: "PU" },
  { key: "Mass-Start", text: "Mass-Start", value: "MS" },
  { key: "Individual", text: "Individual", value: "IN" },
];

const CancelToken = axios.CancelToken;

export class PercentWinContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      data: [],
      empty_datas: true,
      selected_discipline: "all",
    };
  }

  handleFilteredDiscipline = (event, data) => {
    this.setState({ selected_discipline: data.value });
    if (data.value === "all") {
      this.fetchDatas(this.props.gender, this.props.season, this.state.source);
    } else {
      this.fetchDatasFiltered(
        this.props.gender,
        this.props.season,
        data.value,
        this.state.source
      );
    }
  };

  filter_datas(data, filters, filter_countries) {
    let newdata = data;
    if (filters.length > 0 || filter_countries.length > 0) {
      newdata = data.filter(
        (data) =>
          filters.includes(data.id) ||
          filter_countries.includes(data.nationality)
      );
    }
    return newdata;
  }
  add_ranking(data) {
    for (let i = 0; i < data.length; i++) {
      data[i].ranking = i + 1;
    }
    return data;
  }
  pieTransform(data) {
    let new_data = [
      {
        name: "Wins",
        colorByPoint: true,
        data: [],
      },
    ];
    let filtered_wins = 0;
    for (let i = 0; i < data.length; i++) {
      if (data[i].gold_medals > 0) {
        if (i === 0) {
          new_data[0].data.push({
            name:
              data[i].given_name +
              " " +
              data[i].family_name +
              " (" +
              COUNTRY_CODES_CONVERSION[data[i].nationality]["code"] +
              ") ",
            y: data[i].gold_medals,
            sliced: true,
            selected: true,
          });
        } else {
          new_data[0].data.push({
            name:
              data[i].given_name +
              " " +
              data[i].family_name +
              " (" +
              COUNTRY_CODES_CONVERSION[data[i].nationality]["code"] +
              ") ",
            y: data[i].gold_medals,
          });
        }
      }
      filtered_wins += data[i].gold_medals;
    }
    if (
      (this.props.filtered.length > 0 ||
        this.props.filtered_countries.length > 0) &&
      new_data[0].data.length > 0
    ) {
      let total_wins = 0;
      for (let single_data of this.state.data) {
        total_wins += single_data.gold_medals;
      }
      new_data[0].data.push({
        name: "other athletes",
        y: total_wins - filtered_wins,
        color: "#eeeeee",
        dataLabels: {
          enabled: false,
        },
      });
    }
    //empties here
    return new_data;
  }

  isEmptyData(datas) {
    let is_empty = false;
    if (datas[0].data.length === 0) {
      is_empty = true;
    }
    return is_empty;
  }

  fetchDatas = async (gender, season, source) => {
    this.setState({ loading: true, error: false });
    try {
      const { data } = await ApiService.getMedalsTable(
        this.props.season,
        this.props.gender,
        source
      );
      if (gender === this.props.gender && season === this.props.season) {
        this.setState({ data: data });
      }
    } catch (error) {
      if (
        gender === this.props.gender &&
        season === this.props.season &&
        error.message !== "gender change"
      ) {
        this.setState({ error: true });
      }
    }
    if (gender === this.props.gender && season === this.props.season) {
      this.setState({ loading: false });
    }
  };

  fetchDatasFiltered = async (gender, season, discipline, source) => {
    this.setState({ loading: true, error: false });
    try {
      const { data } = await ApiService.getPercentWins(
        this.props.season,
        this.props.gender,
        discipline,
        source
      );
      if (gender === this.props.gender && season === this.props.season) {
        this.setState({ data: data });
      }
    } catch (error) {
      if (
        gender === this.props.gender &&
        season === this.props.season &&
        error.message !== "gender change"
      ) {
        this.setState({ error: true });
      }
    }
    if (gender === this.props.gender && season === this.props.season) {
      this.setState({ loading: false });
    }
  };

  async componentDidMount() {
    const newSource = CancelToken.source();
    this.setState({ source: newSource });
    this.fetchDatas(this.props.gender, this.props.season, newSource);
  }
  async componentDidUpdate(prevProps) {
    if (
      this.props.gender !== prevProps.gender ||
      this.props.season !== prevProps.season
    ) {
      if (this.state.loading) {
        ApiService.cancelFetch(this.state.source);
      }
      const newSource = CancelToken.source();
      this.setState({ data: [], source: newSource });
      if (this.state.selected_discipline === "all") {
        this.fetchDatas(this.props.gender, this.props.season, newSource);
      } else {
        this.fetchDatasFiltered(
          this.props.gender,
          this.props.season,
          this.state.selected_discipline,
          newSource
        );
      }
      this.fetchDatas(this.props.gender, this.props.season, newSource);
    }
  }

  render() {
    return (
      <>
        <Segment className="noborder">
          <Dropdown
            placeholder="Discipline"
            fluid
            selection
            value={this.state.selected_discipline}
            options={options}
            disabled={this.props.loading}
            onChange={this.handleFilteredDiscipline}
          />
        </Segment>

        <PercentWin
          data={this.pieTransform(
            this.filter_datas(
              this.state.data,
              this.props.filtered,
              this.props.filtered_countries
            )
          )}
          loading={this.state.loading}
          error={this.state.error}
          showAthleteCard={this.props.showAthleteCard}
          empty_datas={this.isEmptyData(
            this.pieTransform(
              this.filter_datas(
                this.state.data,
                this.props.filtered,
                this.props.filtered_countries
              )
            )
          )}
        />
      </>
    );
  }
}
