import axios from "axios";
import { API_BASE_URL, PUBLIC_URL } from "./constants";

class ApiService {
  constructor(baseUrl = "/api") {
    this.baseUrl = baseUrl;
  }

  getSingleAthlete(year, id) {
    return axios.get(`${this.baseUrl}/athlete/${year}/${id}`);
  }
  getRanking(year, gender, requestUrl, discipline, is_rall = 0, source) {
    if (discipline) {
      return axios.get(
        `${this.baseUrl}/${requestUrl}/${year}/${gender}/${discipline}`,
        {
          params: {
            rall: is_rall,
          },
          cancelToken: source.token,
        }
      );
    }
    return axios.get(`${this.baseUrl}/${requestUrl}/${year}/${gender}`);
  }
  getRankedList(year, gender) {
    return axios.get(`${this.baseUrl}/rankedlist/${year}/${gender}`);
  }
  getCountriesList(year, gender) {
    return axios.get(`${this.baseUrl}/countries/${year}/${gender}`);
  }
  getShotsAccuracy(year, gender, filterraces = 1, shooting_type, source) {
    return axios.get(`${this.baseUrl}/shotsaccuracy/${year}/${gender}`, {
      params: {
        filterraces: filterraces,
        shootingtype: shooting_type,
      },
      cancelToken: source.token,
    });
  }
  getMedalsTable(year, gender, source) {
    return axios.get(`${this.baseUrl}/medals/${year}/${gender}`, {
      cancelToken: source.token,
    });
  }
  getHistoryRanking(
    year,
    gender,
    filters,
    filter_countries,
    filter_disciplines,
    filter_dropped,
    source
  ) {
    return axios.get(`${this.baseUrl}/historykpi/${year}/${gender}`, {
      params: {
        filterathletes: filters,
        filtercountries: filter_countries,
        filterdisciplines: filter_disciplines,
        filterdropped: filter_dropped,
      },
      cancelToken: source.token,
    });
  }
  getSkiingAverage(year, gender, source) {
    return axios.get(`${this.baseUrl}/skiingranking/${year}/${gender}`, {
      cancelToken: source.token,
    });
  }
  getShootingAverage(year, gender, source) {
    return axios.get(`${this.baseUrl}/shootingranking/${year}/${gender}`, {
      cancelToken: source.token,
    });
  }
  getRaceList(year, gender, source) {
    return axios.get(`${this.baseUrl}/racelist/${year}/${gender}`, {
      cancelToken: source.token,
    });
  }
  getRace(id, source) {
    return axios.get(`${this.baseUrl}/race/${id}`, {
      cancelToken: source.token,
    });
  }
  getLastRace(season, gender, source) {
    return axios.get(`${this.baseUrl}/lastrace/${season}/${gender}`, {
      cancelToken: source.token,
    });
  }
  getSeasons() {
    return axios.get(`${this.baseUrl}/seasons/`);
  }
  getSpecialEvent(season, gender, source) {
    return axios.get(`${this.baseUrl}/specialevent/${season}/${gender}`, {
      cancelToken: source.token,
    });
  }
  cancelFetch(source) {
    source.cancel("gender change");
  }
  getCleanSheet(year, gender, filter_type, source) {
    return axios.get(`${this.baseUrl}/cleansheets/${year}/${gender}`, {
      params: {
        filtertype: filter_type,
      },
      cancelToken: source.token,
    });
  }
  getPercentWins(year, gender, filter_discipline, source) {
    return axios.get(`${this.baseUrl}/percentwins/${year}/${gender}`, {
      params: {
        filterdiscipline: filter_discipline,
      },
      cancelToken: source.token,
    });
  }
  getPointsPerRace(year, gender, source) {
    return axios.get(`${this.baseUrl}/pointsperrace/${year}/${gender}`, {
      cancelToken: source.token,
    });
  }
  getSpeedVsAccuracy(year, gender, shooting_type, source) {
    return axios.get(`${this.baseUrl}/speedvsaccuracy/${year}/${gender}`, {
      params: {
        shootingtype: shooting_type,
      },
      cancelToken: source.token,
    });
  }
  getSpeedVsAccuracyAthlete(athlete_id, shooting_type, source) {
    return axios.get(`${this.baseUrl}/speedvsaccuracyathlete/${athlete_id}`, {
      params: {
        shootingtype: shooting_type,
      },
      cancelToken: source.token,
    });
  }
  getFullList(gender) {
    return axios.get(`${this.baseUrl}/athletefulllist/${gender}`);
  }
  getAllResultsAthlete(athlete_id, race_type, source) {
    return axios.get(`${this.baseUrl}/athleteresultsathlete/${athlete_id}`, {
      params: {
        racetype: race_type,
      },
      cancelToken: source.token,
    });
  }
  getSeasonsRankingsAthlete(athlete_id, race_type, source) {
    return axios.get(`${this.baseUrl}/seasonsrankingsathlete/${athlete_id}`, {
      params: {
        racetype: race_type,
      },
      cancelToken: source.token,
    });
  }
  getSkiingShootingAthlete(athlete_id, source) {
    return axios.get(`${this.baseUrl}/skiingandshootingathlete/${athlete_id}`, {
      cancelToken: source.token,
    });
  }
  getAthleteCard(athlete_id, source) {
    return axios.get(`${this.baseUrl}/athletecard/${athlete_id}`, {
      cancelToken: source.token,
    });
  }
  getTrends(number, season, gender, source) {
    return axios.get(
      `${this.baseUrl}/seasontrends/${number}/${season}/${gender}`,
      {
        cancelToken: source.token,
      }
    );
  }
  getCleanSheetsAthlete(athlete_id, source) {
    return axios.get(`${this.baseUrl}/cleansheetsathlete/${athlete_id}`, {
      cancelToken: source.token,
    });
  }
  getPointsPerRaceAthlete(athlete_id, source) {
    return axios.get(`${this.baseUrl}/pointsperraceathlete/${athlete_id}`, {
      cancelToken: source.token,
    });
  }
  getResultsDistributionAthlete(athlete_id, race, season, source) {
    return axios.get(
      `${this.baseUrl}/resultsdistributionathlete/${athlete_id}/${race}/${season}`,
      {
        cancelToken: source.token,
      }
    );
  }
  getBirthdays(day, month, gender, source) {
    return axios.get(`${this.baseUrl}/birthday/${day}/${month}/${gender}`, {
      cancelToken: source.token,
    });
  }
  getPredictions(source) {
    return axios.get(`${this.baseUrl}/predictions`, {
      cancelToken: source.token,
    });
  }
  getWorldMap(
    year,
    gender,
    filters,
    filter_countries,
    filter_disciplines,
    source
  ) {
    return axios.get(`${this.baseUrl}/worldmap/${year}/${gender}`, {
      params: {
        filterathletes: filters,
        filtercountries: filter_countries,
        filterdisciplines: filter_disciplines,
      },
      cancelToken: source.token,
    });
  }
}

export default new ApiService(`${PUBLIC_URL}${API_BASE_URL}`);
