import React from "react";
import ApiService from "../utils/apiService";
import { Dropdown, Segment } from "semantic-ui-react";
import { SpeedVsAccuracy } from "../components/SpeedVsAccuracy";
import axios from "axios";

const CancelToken = axios.CancelToken;

const options = [
  { key: "All", text: "All Shootings", value: "all" },
  { key: "Prone", text: "Prone shootings", value: "prone" },
  { key: "Standing", text: "Standing shootings", value: "standing" },
];

export class SpeedVsAccuracyAthleteContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      data: [],
      selected_shooting: "all",
    };
  }

  handleFilteredShootings = (event, data) => {
    this.setState({ selected_shooting: data.value });
    this.fetchDatas(this.props.athlete_id, data.value, this.state.source);
  };

  isEmptyData(datas) {
    let is_empty = true;
    for (let data of datas) {
      if (data.data.length > 0) {
        is_empty = false;
      }
    }
    return is_empty;
  }

  fetchDatas = async (athlete_id, shootingtype, source) => {
    this.setState({ loading: true, error: false });
    try {
      const { data } = await ApiService.getSpeedVsAccuracyAthlete(
        athlete_id,
        shootingtype,
        source
      );
      this.setState({ data: data });
    } catch (error) {
      if (error.message !== "gender change") {
        this.setState({ error: true });
      }
    }
    this.setState({ loading: false });
  };

  async componentDidMount() {
    const newSource = CancelToken.source();
    this.setState({ source: newSource });
    this.fetchDatas(
      this.props.athlete_id,
      this.state.selected_shooting,
      newSource
    );
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.props.athlete_id !== prevProps.athlete_id) {
      ApiService.cancelFetch(this.state.source);

      const newSource = CancelToken.source();
      this.setState({ data: [], source: newSource });
      this.fetchDatas(
        this.props.athlete_id,
        this.state.selected_shooting,
        newSource
      );
    }
  }

  render() {
    return (
      <>
        <Segment className="noborder marginplus">
          <Dropdown
            placeholder="All shootings"
            fluid
            selection
            value={this.state.selected_shooting}
            options={options}
            disabled={this.props.loading}
            onChange={this.handleFilteredShootings}
          />
        </Segment>
        <SpeedVsAccuracy
          data={this.state.data}
          loading={this.state.loading}
          error={this.state.error}
          empty_datas={this.isEmptyData(this.state.data)}
          threshold={this.state.threshold}
          show_legend={false}
        />
      </>
    );
  }
}
