import React from "react";
import ApiService from "../utils/apiService";
//import { Dropdown, Segment } from "semantic-ui-react";
import { SeasonsSkiingShootingAthlete } from "../components/SeasonsSkiingShootingAthlete";

import axios from "axios";

const CancelToken = axios.CancelToken;

/*const options = [
  { key: "Overall", text: "All Disciplines", value: "all" },
  { key: "Sprint", text: "Sprint", value: "Sprint" },
  { key: "Pursuit", text: "Pursuit", value: "Pursuit" },
  { key: "Mass-Start", text: "Mass-Start", value: "Mass-Start" },
  { key: "Individual", text: "Individual", value: "Individual" },
];*/

export class SeasonsSkiingShootingAthleteContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      data: [],
      selected_race: "all",
    };
  }

  handleFilteredRaces = (event, data) => {
    this.setState({ selected_race: data.value });
    this.fetchDatas(this.props.athlete_id, data.value, this.state.source);
  };

  isEmptyData(datas) {
    let is_empty = true;
    for (let data of datas) {
      if (data.ski_ranking) {
        is_empty = false;
      }
    }
    return is_empty;
  }

  highchartsData(data) {
    let categories = [];
    let ski_series_data = [];
    let accuracy_series_data = [];
    let shots_series_data = [];
    for (let single_data of data) {
      categories.push(single_data.year);
      if (single_data.ranking === null) {
        ski_series_data.push(null);
      } else {
        ski_series_data.push({
          y: single_data.ski_ranking,
          //average_position: single_data.ski_average_position,
          //average_time: single_data.ski_average_time,
          year: single_data.year,
          plus:
            "</b>" +
            "<br/>average time behind: " +
            single_data.ski_average_time +
            "<br/>average position:" +
            single_data.ski_average_position,
        });
      }
      if (single_data.shots_ranking === null) {
        accuracy_series_data.push(null);
      } else {
        accuracy_series_data.push({
          y: single_data.shots_ranking,
          year: single_data.year,
          plus:
            "</b>" +
            "<br/>accuracy: <b>" +
            single_data.shots_accuracy +
            "%</b> - (" +
            single_data.good_shots +
            "/" +
            single_data.total_shots +
            ")" +
            "<br/>average position:" +
            single_data.ski_average_position,
        });
      }
      if (single_data.ranking === null) {
        shots_series_data.push(null);
      } else {
        shots_series_data.push({
          y: single_data.shots_time_ranking,
          year: single_data.year,
          plus:
            "</b>" +
            "<br/>average time: " +
            single_data.shots_time_average_time +
            "<br/>average position:" +
            single_data.shots_time_average_position,
        });
      }
    }
    let highchartsData = [
      {
        categories: categories,
        series: [
          {
            name: "Skiing Time",
            data: ski_series_data,
          },
          {
            name:
              "Shooting Accuracy (only Athletes running > 1/2 season races)",
            data: accuracy_series_data,
          },
          {
            name: "Shooting Time",
            data: shots_series_data,
          },
        ],
      },
    ];
    return highchartsData;
  }

  fetchDatas = async (athlete_id, source) => {
    this.setState({ loading: true, error: false });
    try {
      const { data } = await ApiService.getSkiingShootingAthlete(
        athlete_id,
        source
      );
      this.setState({ data: data });
    } catch (error) {
      if (error.message !== "gender change") {
        this.setState({ error: true });
      }
    }
    this.setState({ loading: false });
  };

  async componentDidMount() {
    const newSource = CancelToken.source();
    this.setState({ source: newSource });
    this.fetchDatas(this.props.athlete_id, newSource);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.props.athlete_id !== prevProps.athlete_id) {
      ApiService.cancelFetch(this.state.source);

      const newSource = CancelToken.source();
      this.setState({ data: [], source: newSource });
      this.fetchDatas(this.props.athlete_id, newSource);
    }
  }

  render() {
    return (
      <>
        {/*<Segment className="noborder marginplus">
          <Dropdown
            placeholder="All shootings"
            fluid
            selection
            value={this.state.selected_race}
            options={options}
            disabled={this.props.loading}
            onChange={this.handleFilteredRaces}
          />
    </Segment>*/}
        <SeasonsSkiingShootingAthlete
          data={this.highchartsData(this.state.data)}
          loading={this.state.loading}
          error={false}
          empty_datas={this.isEmptyData(this.state.data)}
          //empty_datas={false}
        />
      </>
    );
  }
}
