import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Loader, Message } from "semantic-ui-react";

export class SpeedVsAccuracy extends React.PureComponent {
  get options() {
    return {
      chart: {
        height: 355,
        type: "scatter",
        zoomType: "xy",
      },
      title: {
        text: null,
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: this.props.show_legend,
      },
      xAxis: {
        title: {
          enabled: true,
          text: "shooting time (in seconds)",
        },
        startOnTick: true,
        endOnTick: true,
        showLastLabel: true,
      },
      yAxis: {
        title: {
          text: "Hits (/5 balls)",
        },
        max: 5,
        min: 0,
        tickInterval: 1,
      },
      plotOptions: {
        scatter: {
          marker: {
            radius: 5,
            states: {
              hover: {
                enabled: true,
                lineColor: "rgb(100,100,100)",
              },
            },
          },
          states: {
            hover: {
              marker: {
                enabled: false,
              },
            },
          },
          tooltip: {
            headerFormat: "<b>{series.name}</b><br>",
            pointFormat: '{point.x} "", hits: {point.y}/5',
          },
        },
        series: {
          turboThreshold: 0,
        },
      },
      series: this.props.data,
    };
  }

  render() {
    if (this.props.error) {
      return (
        <Message
          negative
          className="text_center"
          content="Sorry, an error occured"
        ></Message>
      );
    }
    if (this.props.loading) {
      return (
        <div className="LoaderContainer">
          <Loader active content="Loading"></Loader>
        </div>
      );
    }
    return (
      <>
        {this.props.empty_datas ? (
          <Message
            className="text_center"
            content="No data to display"
          ></Message>
        ) : (
          <div className="highcharts_content">
            <HighchartsReact highcharts={Highcharts} options={this.options} />
          </div>
        )}
      </>
    );
  }
}
