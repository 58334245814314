import React from "react";
import { Loader, Message } from "semantic-ui-react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export class ResultsDistribAthlete extends React.PureComponent {
  get options() {
    return {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
        height: 236,
      },
      title: {
        text: null,
      },
      credits: {
        enabled: false,
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          },
        },
      },
      series: this.props.data,
    };
  }

  render() {
    if (this.props.error) {
      return (
        <Message
          negative
          className="text_center"
          content="Sorry, an error occured"
        ></Message>
      );
    }
    if (
      this.props.loading ||
      (this.props.data &&
        this.props.data.data &&
        this.props.data.data[0].length === 0)
    ) {
      return (
        <div className="LoaderContainer">
          <Loader active content="Loading"></Loader>
        </div>
      );
    }
    if (
      this.props.data &&
      this.props.data.data &&
      this.props.data[0].data.length === 0
    ) {
      return (
        <Message className="text_center" content="No data to display"></Message>
      );
    }
    return (
      <>
        {this.props.empty_datas ? (
          <Message
            className="text_center"
            content="No data to display"
          ></Message>
        ) : (
          <HighchartsReact highcharts={Highcharts} options={this.options} />
        )}
      </>
    );
  }
}
