import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Loader, Message } from "semantic-ui-react";

export class PointsPerRaceAthlete extends React.PureComponent {
  get options() {
    return {
      chart: {
        height: 210,
      },
      title: {
        text: null,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: this.props.data.categories,
      },
      yAxis: {
        title: {
          text: null,
        },
        tickInterval: 5,
        labels: {
          formatter: function() {
            if (this.value !== 0) {
              return this.value;
            }
          },
        },
        gridLineWidth: 0,
        allowDecimals: false,
        min: 0,
      },
      legend: {
        enabled: true,
      },

      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
          /* Here is the setting to limit the maximum column width. */
          maxPointWidth: 26,
          dataLabels: {
            enabled: true,
          },
        },
      },
      /*tooltip: {
        formatter: function() {
          return (
            "<b>" +
            this.x +
            "</b><br/>" +
            this.series.name +
            ": " +
            this.y +
            "<br/>" +
            "Total: " +
            this.point.stackTotal
          );
        },
      },*/
      series: this.props.data.series,
    };
  }
  get options_single() {
    return {
      chart: {
        height: 122,
      },
      title: {
        text: null,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: this.props.data.categories_single,
      },
      yAxis: {
        title: {
          text: null,
        },
        tickInterval: 5,
        labels: {
          formatter: function() {
            if (this.value !== 0) {
              return this.value;
            }
          },
        },
        gridLineWidth: 0,
        allowDecimals: false,
        min: 0,
      },
      legend: {
        enabled: false,
      },

      plotOptions: {
        bar: {
          pointPadding: 0.2,
          borderWidth: 0,
          /* Here is the setting to limit the maximum column width. */
          maxPointWidth: 16,
          dataLabels: {
            enabled: false,
          },
        },
      },
      /*tooltip: {
        formatter: function() {
          return (
            "<b>" +
            this.x +
            "</b><br/>" +
            this.series.name +
            ": " +
            this.y +
            "<br/>" +
            "Total: " +
            this.point.stackTotal
          );
        },
      },*/
      series: this.props.data.series_single,
    };
  }

  render() {
    if (this.props.error) {
      return (
        <Message
          negative
          className="text_center"
          content="Sorry, an error occured"
        ></Message>
      );
    }
    if (this.props.loading) {
      return (
        <div className="LoaderContainer">
          <Loader active content="Loading"></Loader>
        </div>
      );
    }
    return (
      <>
        {this.props.empty_datas ? (
          <Message
            className="text_center"
            content="No data to display"
          ></Message>
        ) : (
          <>
            <div className="highcharts_content">
              <HighchartsReact
                highcharts={Highcharts}
                options={this.options_single}
              />
            </div>
            <div className="highcharts_content">
              <HighchartsReact highcharts={Highcharts} options={this.options} />
            </div>
          </>
        )}
      </>
    );
  }
}
