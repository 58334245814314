import React from "react";
import ApiService from "../utils/apiService";
import { HistoryLastResults } from "../components/HistoryLastResults";
import { Dropdown } from "semantic-ui-react";
import axios from "axios";

const CancelToken = axios.CancelToken;

const options = [
  { key: "All", text: "All Disciplines", value: "all" },
  { key: "Sprint", text: "Sprint", value: "SP" },
  { key: "Pursuit", text: "Pursuit", value: "PU" },
  { key: "Mass-Start", text: "Mass-Start", value: "MS" },
  { key: "Individual", text: "Individual", value: "IN" },
];

export class HistoryLastResultsContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      data: [],
      empty_datas: true,
      filtered_disciplines: "all",
    };
  }

  isEmptyData(datas) {
    let is_empty = true;
    for (let data of datas[0].results) {
      for (let single_data of data.data) {
        if (single_data.y !== null) {
          is_empty = false;
        }
      }
    }
    return is_empty;
  }

  handleFilteredDiscipline = (event, data) => {
    this.setState({ filtered_disciplines: data.value });
  };

  fetchDatas = async (gender, season, source) => {
    this.setState({ loading: true });
    const athletes = this.props.filtered.join(",");
    const countries = this.props.filtered_countries.join(",");
    const disciplines = this.state.filtered_disciplines;
    try {
      const { data } = await ApiService.getHistoryRanking(
        this.props.season,
        this.props.gender,
        athletes === "" ? null : athletes,
        countries === "" ? null : countries,
        disciplines === "" || disciplines === "all" ? null : disciplines,
        null,
        source
      );
      if (gender === this.props.gender && season === this.props.season) {
        this.setState({ data: data });
        this.setState({ empty_datas: this.isEmptyData(data) });
      }
    } catch (error) {
      if (
        gender === this.props.gender &&
        season === this.props.season &&
        error.message !== "gender change"
      ) {
        this.setState({ error: true });
      }
    }
    if (gender === this.props.gender && season === this.props.season) {
      this.setState({ loading: false });
    }
  };

  async componentDidMount() {
    const newSource = CancelToken.source();
    this.setState({ source: newSource });
    this.fetchDatas(this.props.gender, this.props.season, newSource);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.props.filtered !== prevProps.filtered ||
      this.props.filtered_countries !== prevProps.filtered_countries ||
      this.state.filtered_disciplines !== prevState.filtered_disciplines ||
      this.props.season !== prevProps.season
    ) {
      ApiService.cancelFetch(this.state.source);

      const newSource = CancelToken.source();
      this.setState({ data: [], source: newSource });
      this.fetchDatas(this.props.gender, this.props.season, newSource);
    }
  }

  render() {
    return (
      <>
        <Dropdown
          placeholder="Discipline"
          fluid
          selection
          value={this.state.filtered_disciplines}
          options={options}
          className="select_discipline"
          onChange={this.handleFilteredDiscipline}
        />
        <HistoryLastResults
          data={this.state.data}
          loading={this.state.loading}
          error={this.state.error}
          empty_datas={this.state.empty_datas}
        />
      </>
    );
  }
}
