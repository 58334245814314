import React from "react";
import {
  Grid,
  Segment,
  Header,
  Icon,
  Sidebar,
  Dropdown,
  Loader,
} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import ApiService from "../utils/apiService";

import { RankingContainer } from "./RankingContainer";
import { ShotsAccuracyContainer } from "./ShotsAccuracyContainer";
import { MedalsTableContainer } from "./MedalsTableContainer";
import { HistoryRankingContainer } from "./HistoryRankingContainer";
import { HistoryLastResultsContainer } from "./HistoryLastResultsContainer";
import { PointsPerRaceContainer } from "./PointsPerRaceContainer";
import { SpeedVsAccuracyContainer } from "./SpeedVsAccuracyContainer";
import { FiltersContainer } from "./FiltersContainer";
import { FilterCountriesContainer } from "./FilterCountriesContainer";
import { SkiingTimeRankingContainer } from "./SkiingTimeRankingContainer";
import { ShootingTimeRankingContainer } from "./ShootingTimeRankingContainer";
import { AthleteCard } from "../components/AthleteCard";
import { SingleRaceContainer } from "./SingleRaceContainer";
import { SpecialEventContainer } from "./SpecialEventContainer";
import { PercentWinContainer } from "./PercentWinContainer";
import { CleanSheetContainer } from "./CleanSheetContainer";
import { CurrentTrendsContainer } from "./CurrentTrendsContainer";
import { WorldMapContainer } from "./WorldMapContainer";

const genderOptions = [
  {
    key: "SM",
    text: (
      <span>
        <Icon name="man" />
        Men
      </span>
    ),
    value: "SM",
    //icon: "man",
  },
  {
    key: "SW",
    text: (
      <span>
        <Icon name="woman" />
        Women
      </span>
    ),
    value: "SW",
    //icon: "woman",
  },
];
const genderLabels = {
  SW: "Women",
  SM: "Men",
};

class NCHome extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      filtered_athletes: [],
      filtered_countries: [],
      visible: false,
      athlete_data: {},
      athlete_loading: true,
      athlete_error: null,
      currentGender: "SM",
      currentSeason: null,
      single_race_fullView: false,
      seasons: [],
      special_event: "...",
    };
  }

  fetchDatas = async () => {
    this.setState({ loading: true, error: false });
    try {
      const { data } = await ApiService.getSeasons();
      this.setState({ seasons: data, currentSeason: data[0].value });
    } catch (error) {
      this.setState({ error: true });
    }
    this.setState({ loading: false });
  };

  async componentDidMount() {
    this.fetchDatas();
  }

  changeSingleRaceView = () => {
    this.setState({ single_race_fullView: !this.state.single_race_fullView });
  };

  handleSeasonChange = (event, data) => {
    this.setState({
      currentSeason: data.value,
    });
  };

  handleSeasonChange = (event, data) => {
    this.setState({
      currentSeason: data.value,
      filtered_athletes: [],
      filtered_countries: [],
      visible: false,
    });
  };

  handleGenderChange = (event, data) => {
    this.setState({
      currentGender: data.value,
      filtered_athletes: [],
      filtered_countries: [],
      visible: false,
    });
  };

  handleFilteredAthletes = (event, data) => {
    this.setState({ filtered_athletes: data.value });
    if (data.value.length > 0) {
      this.setState({ single_race_fullView: true });
    }
  };
  handleFilteredCountries = (event, data) => {
    this.setState({ filtered_countries: data.value });
    if (data.value.length > 0) {
      this.setState({ single_race_fullView: true });
    }
  };
  handleFilteredAthletesEraser = () => {
    this.setState({ filtered_athletes: [] });
  };
  handleFilteredCountriesEraser = () => {
    this.setState({ filtered_countries: [] });
  };

  showAthleteCard = async (visibility, athlete_id) => {
    const season = this.state.currentSeason;
    this.setState({ visible: visibility });
    if (visibility) {
      this.setState({ athlete_loading: true, athlete_error: false });
      try {
        const { data } = await ApiService.getSingleAthlete(season, athlete_id);
        this.setState({ athlete_data: data });
      } catch (error) {
        this.setState({ athlete_error: true });
      }
      this.setState({ athlete_loading: false });
    }
  };

  setSpecialEventType = (eventType) => {
    this.setState({ special_event: eventType });
  };

  render() {
    return (
      <>
        <Sidebar.Pushable as={Segment}>
          <Sidebar
            animation="push"
            width="very wide"
            icon="labeled"
            visible={this.state.visible}
            //onHide={() => this.showAthleteCard(false, null)}
            className="sidebar_athlete"
          >
            {this.state.loading ? (
              <div className="LoaderContainer">
                <Loader active content="Loading"></Loader>
              </div>
            ) : (
              <AthleteCard
                loading={this.state.athlete_loading}
                data={this.state.athlete_data}
                error={this.state.athlete_error}
                showAthleteCard={this.showAthleteCard}
                gender={this.state.currentGender}
                season={this.state.currentSeason}
              />
            )}
          </Sidebar>
          <Sidebar.Pusher>
            <div id="container">
              <Header as="h1" textAlign="center" className="main_header">
                <img
                  src="static/img/logo_bs.png"
                  alt="biathlon stats"
                  className="logo_img"
                />
                <Dropdown
                  placeholder="gender"
                  options={genderOptions}
                  defaultValue={genderOptions[0].value}
                  onChange={this.handleGenderChange}
                />
                <Dropdown
                  loading={this.state.loading}
                  placeholder="season"
                  options={this.state.seasons}
                  value={this.state.currentSeason}
                  onChange={this.handleSeasonChange}
                />
              </Header>
              <Grid className="filters_home">
                <Grid.Row columns={6} className="filters_area_men">
                  <Grid.Column width={1} className="margin_grid" />
                  <Grid.Column width={2} className="filters_legend">
                    <div className="filters-container">
                      <Icon name="filter" /> Filter by Athletes:
                      <Icon
                        name="eraser"
                        className="eraser-styles"
                        onClick={this.handleFilteredAthletesEraser}
                      />
                    </div>
                  </Grid.Column>
                  <Grid.Column width={5} className="filters_dropdown">
                    <div className="filters-container">
                      <FiltersContainer
                        handleFilteredAthletes={this.handleFilteredAthletes}
                        gender={this.state.currentGender}
                        season={this.state.currentSeason}
                        filtersValue={this.state.filtered_athletes}
                      />
                    </div>
                  </Grid.Column>
                  <Grid.Column width={2} className="filters_legend">
                    <div className="filters-container">
                      <Icon name="filter" /> Filter by Countries:
                      <Icon
                        name="eraser"
                        className="eraser-styles"
                        onClick={this.handleFilteredCountriesEraser}
                      />
                    </div>
                  </Grid.Column>
                  <Grid.Column width={5} className="filters_dropdown">
                    <div className="filters-container">
                      <FilterCountriesContainer
                        handleFilteredCountries={this.handleFilteredCountries}
                        gender={this.state.currentGender}
                        season={this.state.currentSeason}
                        filtersValue={this.state.filtered_countries}
                      />
                    </div>
                  </Grid.Column>
                  <Grid.Column width={1} className="margin_grid" />
                </Grid.Row>
              </Grid>
              <Grid stackable>
                <Grid.Row columns={1}>
                  <Grid.Column>
                    {this.state.loading ? (
                      <div className="LoaderContainer">
                        <Loader active content="Loading"></Loader>
                      </div>
                    ) : (
                      <SingleRaceContainer
                        gender={this.state.currentGender}
                        season={this.state.currentSeason}
                        filtered={this.state.filtered_athletes}
                        filtered_countries={this.state.filtered_countries}
                        showAthleteCard={this.showAthleteCard}
                        changeSingleRaceView={this.changeSingleRaceView}
                        single_race_fullView={this.state.single_race_fullView}
                      />
                    )}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Segment>
                      <Header as="h2" textAlign="center">
                        <span className="float-left">
                          <Icon name="chart bar" />
                        </span>
                        {genderLabels[this.state.currentGender]} - Last Results
                        (default: TOP 5)
                      </Header>
                      <div className="history-container">
                        {this.state.loading ? (
                          <div className="LoaderContainer">
                            <Loader active content="Loading"></Loader>
                          </div>
                        ) : (
                          <HistoryLastResultsContainer
                            gender={this.state.currentGender}
                            season={this.state.currentSeason}
                            filtered={this.state.filtered_athletes}
                            filtered_countries={this.state.filtered_countries}
                          />
                        )}
                      </div>
                    </Segment>
                  </Grid.Column>
                  <Grid.Column>
                    <Segment>
                      <Header as="h2" textAlign="center">
                        <span className="float-left">
                          <Icon name="chart bar" />
                        </span>
                        {genderLabels[this.state.currentGender]} - Ranking
                        Evolution (default: TOP 5)
                      </Header>
                      <div className="history-container">
                        {this.state.loading ? (
                          <div className="LoaderContainer">
                            <Loader active content="Loading"></Loader>
                          </div>
                        ) : (
                          <HistoryRankingContainer
                            gender={this.state.currentGender}
                            season={this.state.currentSeason}
                            filtered={this.state.filtered_athletes}
                            filtered_countries={this.state.filtered_countries}
                          />
                        )}
                      </div>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                  <Grid.Column>
                    <Segment>
                      <Header as="h2" textAlign="center">
                        <span className="float-left">
                          <Icon name="chart bar" />
                        </span>
                        {genderLabels[this.state.currentGender]} - Current
                        trends
                      </Header>
                      <div className="ranking-container3 noborder">
                        {this.state.loading ? (
                          <div className="LoaderContainer">
                            <Loader active content="Loading"></Loader>
                          </div>
                        ) : (
                          <CurrentTrendsContainer
                            gender={this.state.currentGender}
                            season={this.state.currentSeason}
                            filtered={this.state.filtered_athletes}
                            filtered_countries={this.state.filtered_countries}
                            showAthleteCard={this.showAthleteCard}
                          />
                        )}
                      </div>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Segment>
                      <Header
                        as="h2"
                        textAlign="center"
                        className="ranking_title"
                      >
                        <span className="float-left">
                          <Icon name="trophy" />
                        </span>
                        {genderLabels[this.state.currentGender]} - Global
                        Ranking with Dropped Results
                      </Header>
                      <div className="ranking-container">
                        {this.state.loading ? (
                          <div className="LoaderContainer">
                            <Loader active content="Loading"></Loader>
                          </div>
                        ) : (
                          <RankingContainer
                            gender={this.state.currentGender}
                            season={this.state.currentSeason}
                            discipline={null}
                            requestUrl="realranking"
                            filtered={this.state.filtered_athletes}
                            filtered_countries={this.state.filtered_countries}
                            showAthleteCard={this.showAthleteCard}
                          />
                        )}
                      </div>
                    </Segment>
                  </Grid.Column>
                  <Grid.Column>
                    <Segment>
                      <Header
                        as="h2"
                        textAlign="center"
                        className="ranking_title"
                      >
                        <span className="float-left">
                          <Icon name="trophy" />
                        </span>
                        {genderLabels[this.state.currentGender]} - Global
                        Ranking
                      </Header>
                      <div className="ranking-container">
                        {this.state.loading ? (
                          <div className="LoaderContainer">
                            <Loader active content="Loading"></Loader>
                          </div>
                        ) : (
                          <RankingContainer
                            gender={this.state.currentGender}
                            season={this.state.currentSeason}
                            discipline={null}
                            requestUrl="ranking"
                            filtered={this.state.filtered_athletes}
                            filtered_countries={this.state.filtered_countries}
                            showAthleteCard={this.showAthleteCard}
                          />
                        )}
                      </div>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Segment>
                      <Header as="h2" textAlign="center">
                        <span className="float-left">
                          <Icon name="chart bar" />
                        </span>
                        {genderLabels[this.state.currentGender]} - Points per
                        race (default: TOP 5)
                      </Header>
                      <div className="history-container">
                        {this.state.loading ? (
                          <div className="LoaderContainer">
                            <Loader active content="Loading"></Loader>
                          </div>
                        ) : (
                          <PointsPerRaceContainer
                            gender={this.state.currentGender}
                            season={this.state.currentSeason}
                            filtered={this.state.filtered_athletes}
                            filtered_countries={this.state.filtered_countries}
                          />
                        )}
                      </div>
                    </Segment>
                  </Grid.Column>
                  <Grid.Column>
                    <Segment>
                      <Header as="h2" textAlign="center">
                        <span className="float-left">
                          <Icon name="sun" />
                        </span>
                        {genderLabels[this.state.currentGender]} - Win Percents
                      </Header>
                      <div className="history-container ">
                        {this.state.loading ? (
                          <div className="LoaderContainer">
                            <Loader active content="Loading"></Loader>
                          </div>
                        ) : (
                          <PercentWinContainer
                            gender={this.state.currentGender}
                            season={this.state.currentSeason}
                            filtered={this.state.filtered_athletes}
                            filtered_countries={this.state.filtered_countries}
                            showAthleteCard={this.showAthleteCard}
                          />
                        )}
                      </div>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                  <Grid.Column>
                    <Segment>
                      <Header as="h2" textAlign="center">
                        <span className="float-left">
                          <Icon name="target" />
                        </span>
                        {genderLabels[this.state.currentGender]} - Shots
                        Accuracy
                      </Header>
                      <div className="ranking-container">
                        {this.state.loading ? (
                          <div className="LoaderContainer">
                            <Loader active content="Loading"></Loader>
                          </div>
                        ) : (
                          <ShotsAccuracyContainer
                            gender={this.state.currentGender}
                            season={this.state.currentSeason}
                            filtered={this.state.filtered_athletes}
                            filtered_countries={this.state.filtered_countries}
                            showAthleteCard={this.showAthleteCard}
                          />
                        )}
                      </div>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Segment>
                      <Header as="h2" textAlign="center">
                        <span className="float-left">
                          <Icon name="clock outline" />
                        </span>
                        {genderLabels[this.state.currentGender]} - Average
                        Skiing Time
                      </Header>
                      <div className="ranking-container">
                        {this.state.loading ? (
                          <div className="LoaderContainer">
                            <Loader active content="Loading"></Loader>
                          </div>
                        ) : (
                          <SkiingTimeRankingContainer
                            gender={this.state.currentGender}
                            season={this.state.currentSeason}
                            filtered={this.state.filtered_athletes}
                            filtered_countries={this.state.filtered_countries}
                            showAthleteCard={this.showAthleteCard}
                          />
                        )}
                      </div>
                    </Segment>
                  </Grid.Column>
                  <Grid.Column>
                    <Segment>
                      <Header as="h2" textAlign="center">
                        <span className="float-left">
                          <Icon name="clock outline" />
                        </span>
                        {genderLabels[this.state.currentGender]} - Average
                        Shooting Time
                      </Header>
                      <div className="ranking-container">
                        {this.state.loading ? (
                          <div className="LoaderContainer">
                            <Loader active content="Loading"></Loader>
                          </div>
                        ) : (
                          <ShootingTimeRankingContainer
                            gender={this.state.currentGender}
                            season={this.state.currentSeason}
                            filtered={this.state.filtered_athletes}
                            filtered_countries={this.state.filtered_countries}
                            showAthleteCard={this.showAthleteCard}
                          />
                        )}
                      </div>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Segment>
                      <Header as="h2" textAlign="center">
                        <span className="float-left">
                          <Icon name="target" />
                        </span>
                        {genderLabels[this.state.currentGender]} - Shooting
                        Speed vs Accuracy (default: TOP 5)
                      </Header>
                      <div className="history-container">
                        {this.state.loading ? (
                          <div className="LoaderContainer">
                            <Loader active content="Loading"></Loader>
                          </div>
                        ) : (
                          <SpeedVsAccuracyContainer
                            gender={this.state.currentGender}
                            season={this.state.currentSeason}
                            filtered={this.state.filtered_athletes}
                            filtered_countries={this.state.filtered_countries}
                          />
                        )}
                      </div>
                    </Segment>
                  </Grid.Column>

                  <Grid.Column>
                    <Segment>
                      <Header as="h2" textAlign="center">
                        <span className="float-left">
                          <Icon name="target" />
                        </span>
                        {genderLabels[this.state.currentGender]} - Clean Sheets
                      </Header>
                      <div className="ranking-container">
                        {this.state.loading ? (
                          <div className="LoaderContainer">
                            <Loader active content="Loading"></Loader>
                          </div>
                        ) : (
                          <CleanSheetContainer
                            gender={this.state.currentGender}
                            season={this.state.currentSeason}
                            filtered={this.state.filtered_athletes}
                            filtered_countries={this.state.filtered_countries}
                            showAthleteCard={this.showAthleteCard}
                          />
                        )}
                      </div>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                  <Grid.Column>
                    <Segment>
                      <Header as="h2" textAlign="center">
                        <span className="float-left">
                          <Icon name="sun" />
                        </span>
                        {genderLabels[this.state.currentGender]} - Honours Board
                      </Header>
                      <div className="ranking-container">
                        {this.state.loading ? (
                          <div className="LoaderContainer">
                            <Loader active content="Loading"></Loader>
                          </div>
                        ) : (
                          <MedalsTableContainer
                            gender={this.state.currentGender}
                            season={this.state.currentSeason}
                            filtered={this.state.filtered_athletes}
                            filtered_countries={this.state.filtered_countries}
                            showAthleteCard={this.showAthleteCard}
                          />
                        )}
                      </div>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Segment>
                      <Header
                        as="h2"
                        textAlign="center"
                        className="ranking_title"
                      >
                        <span className="float-left">
                          <Icon name="trophy" />
                        </span>
                        {genderLabels[this.state.currentGender]} - Sprint
                        Ranking
                      </Header>
                      <div className="ranking-container">
                        {this.state.loading ? (
                          <div className="LoaderContainer">
                            <Loader active content="Loading"></Loader>
                          </div>
                        ) : (
                          <RankingContainer
                            gender={this.state.currentGender}
                            season={this.state.currentSeason}
                            discipline="SP"
                            requestUrl="ranking"
                            filtered={this.state.filtered_athletes}
                            filtered_countries={this.state.filtered_countries}
                            showAthleteCard={this.showAthleteCard}
                          />
                        )}
                      </div>
                    </Segment>
                  </Grid.Column>
                  <Grid.Column>
                    <Segment>
                      <Header
                        as="h2"
                        textAlign="center"
                        className="ranking_title"
                      >
                        <span className="float-left">
                          <Icon name="trophy" />
                        </span>
                        {genderLabels[this.state.currentGender]} - Pursuit
                        Ranking
                      </Header>
                      <div className="ranking-container">
                        {this.state.loading ? (
                          <div className="LoaderContainer">
                            <Loader active content="Loading"></Loader>
                          </div>
                        ) : (
                          <RankingContainer
                            gender={this.state.currentGender}
                            season={this.state.currentSeason}
                            discipline="PU"
                            requestUrl="ranking"
                            filtered={this.state.filtered_athletes}
                            filtered_countries={this.state.filtered_countries}
                            showAthleteCard={this.showAthleteCard}
                          />
                        )}
                      </div>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Segment>
                      <Header
                        as="h2"
                        textAlign="center"
                        className="ranking_title"
                      >
                        <span className="float-left">
                          <Icon name="trophy" />
                        </span>
                        {genderLabels[this.state.currentGender]}- Mass Start
                        Ranking
                      </Header>
                      <div className="ranking-container">
                        {this.state.loading ? (
                          <div className="LoaderContainer">
                            <Loader active content="Loading"></Loader>
                          </div>
                        ) : (
                          <RankingContainer
                            gender={this.state.currentGender}
                            season={this.state.currentSeason}
                            discipline="MS"
                            requestUrl="ranking"
                            filtered={this.state.filtered_athletes}
                            filtered_countries={this.state.filtered_countries}
                            showAthleteCard={this.showAthleteCard}
                          />
                        )}
                      </div>
                    </Segment>
                  </Grid.Column>
                  <Grid.Column>
                    <Segment>
                      <Header
                        as="h2"
                        textAlign="center"
                        className="ranking_title"
                      >
                        <span className="float-left">
                          <Icon name="trophy" />
                        </span>
                        {genderLabels[this.state.currentGender]} - Individual
                        Ranking
                      </Header>
                      <div className="ranking-container">
                        {this.state.loading ? (
                          <div className="LoaderContainer">
                            <Loader active content="Loading"></Loader>
                          </div>
                        ) : (
                          <RankingContainer
                            gender={this.state.currentGender}
                            season={this.state.currentSeason}
                            discipline="IN"
                            requestUrl="ranking"
                            filtered={this.state.filtered_athletes}
                            filtered_countries={this.state.filtered_countries}
                            showAthleteCard={this.showAthleteCard}
                          />
                        )}
                      </div>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                  <Grid.Column>
                    <Segment>
                      <Header as="h2" textAlign="center">
                        <span className="float-left">
                          <Icon name="sun" />
                        </span>
                        {genderLabels[this.state.currentGender]} -{" "}
                        {this.state.special_event}
                      </Header>
                      <div className="ranking-container">
                        {this.state.loading ? (
                          <div className="LoaderContainer">
                            <Loader active content="Loading"></Loader>
                          </div>
                        ) : (
                          <SpecialEventContainer
                            gender={this.state.currentGender}
                            season={this.state.currentSeason}
                            filtered={this.state.filtered_athletes}
                            filtered_countries={this.state.filtered_countries}
                            showAthleteCard={this.showAthleteCard}
                            setSpecialEventType={this.setSpecialEventType}
                          />
                        )}
                      </div>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                  <Grid.Column>
                    <Segment>
                      <Header as="h2" textAlign="center">
                        <span className="float-left">
                          <Icon name="world" />
                        </span>
                        {genderLabels[this.state.currentGender]} - Average
                        points per Country per Athlete
                      </Header>
                      <div>
                        {this.state.loading ? (
                          <div className="LoaderContainer">
                            <Loader active content="Loading"></Loader>
                          </div>
                        ) : (
                          <WorldMapContainer
                            gender={this.state.currentGender}
                            season={this.state.currentSeason}
                            filtered={this.state.filtered_athletes}
                            filtered_countries={this.state.filtered_countries}
                          />
                        )}
                      </div>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
              </Grid>

              <Segment vertical textAlign="center">
                <h4>
                  Athlete pictures:{" "}
                  <a href="https://www.biathlonworld.com/">
                    https://www.biathlonworld.com/
                  </a>
                </h4>
              </Segment>

              <Segment vertical textAlign="center">
                <h4>
                  <a href="https://twitter.com/biathstats">
                    <span className="twitter_content">
                      <Icon name="twitter" />
                    </span>
                  </a>
                </h4>
              </Segment>
            </div>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </>
    );
  }
}

export const HomePage = withRouter(NCHome);
