import React from "react";
import ApiService from "../utils/apiService";
import { WorldMap } from "../components/WorldMap";
import { Dropdown, Grid } from "semantic-ui-react";
import axios from "axios";

const CancelToken = axios.CancelToken;

const options_discipline = [
  { key: "All", text: "All Disciplines", value: "all" },
  { key: "Sprint", text: "Sprint", value: "SP" },
  { key: "Pursuit", text: "Pursuit", value: "PU" },
  { key: "Mass-Start", text: "Mass-Start", value: "MS" },
  { key: "Individual", text: "Individual", value: "IN" },
];

export class WorldMapContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      data: [],
      empty_datas: true,
      filtered_dropped: "",
      filtered_disciplines: "all",
    };
  }

  isEmptyData(datas) {
    if (datas.length === 0) {
      return true;
    }
    return false;

    /*let is_empty = true;
    for (let data of datas[0].series) {
      for (let single_data of data.data) {
        if (single_data !== null) {
          is_empty = false;
        }
      }
    }
    return is_empty;*/
  }

  fetchDatas = async (gender, season, source) => {
    this.setState({ loading: true });
    const athletes = this.props.filtered.join(",");
    const countries = this.props.filtered_countries.join(",");
    //const dropped = this.state.filtered_dropped;
    const disciplines = this.state.filtered_disciplines;
    try {
      const { data } = await ApiService.getWorldMap(
        this.props.season,
        this.props.gender,
        athletes === "" ? null : athletes,
        countries === "" ? null : countries,
        disciplines === "" || disciplines === "all" ? null : disciplines,
        source
      );
      console.log(data);
      if (gender === this.props.gender && season === this.props.season) {
        console.log("newdata");
        this.setState({ data: data });
        this.setState({ empty_datas: this.isEmptyData(data) });
      }
    } catch (error) {
      if (
        gender === this.props.gender &&
        season === this.props.season &&
        error.message !== "gender change"
      ) {
        this.setState({ error: true });
      }
    }
    if (gender === this.props.gender && season === this.props.season) {
      this.setState({ loading: false });
    }
  };

  handleFilteredDropped = (event, data) => {
    this.setState({ filtered_dropped: data.value });
  };

  handleFilteredDiscipline = (event, data) => {
    this.setState({ filtered_disciplines: data.value });
  };

  async componentDidMount() {
    const newSource = CancelToken.source();
    this.setState({ source: newSource });
    this.fetchDatas(this.props.gender, this.props.season, newSource);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.props.season !== prevProps.season) {
      ApiService.cancelFetch(this.state.source);

      const newSource = CancelToken.source();
      this.setState({ data: [], source: newSource, filtered_dropped: "" });
      this.fetchDatas(this.props.gender, this.props.season, newSource);
    } else {
      if (
        this.props.filtered !== prevProps.filtered ||
        this.props.filtered_countries !== prevProps.filtered_countries ||
        this.state.filtered_dropped !== prevState.filtered_dropped ||
        this.state.filtered_disciplines !== prevState.filtered_disciplines
      ) {
        ApiService.cancelFetch(this.state.source);

        const newSource = CancelToken.source();
        this.setState({ data: [], source: newSource });
        this.fetchDatas(this.props.gender, this.props.season, newSource);
      }
    }
  }

  render() {
    return (
      <>
        {/*<Grid columns="equal">
          <Grid.Row>
            <Grid.Column>
              <Dropdown
                placeholder="Discipline"
                fluid
                disabled={this.state.loading}
                selection
                value={this.state.filtered_disciplines}
                options={options_discipline}
                className="select_discipline"
                onChange={this.handleFilteredDiscipline}
              />
            </Grid.Column>
          </Grid.Row>
    </Grid>*/}
        <WorldMap
          data={this.state.data}
          loading={this.state.loading}
          error={this.state.error}
          empty_datas={this.state.empty_datas}
        />
      </>
    );
  }
}
