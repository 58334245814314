import React from "react";
import { Table, Header, Loader, Message, Icon } from "semantic-ui-react";
//import ReactCountryFlag from "react-country-flag";
import { COUNTRY_CODES_CONVERSION } from "../utils/constants";

export class Ranking extends React.PureComponent {
  render() {
    if (this.props.error) {
      return (
        <Message
          negative
          className="text_center"
          content="Sorry, an error occured"
        ></Message>
      );
    }
    if (this.props.loading) {
      return (
        <div className="LoaderContainer">
          <Loader active content="Loading"></Loader>
        </div>
      );
    }
    if (this.props.data.length === 0) {
      return (
        <Message className="text_center" content="No data to display"></Message>
      );
    }
    return (
      <Table unstackable striped className="ranking_results_table">
        <Table.Body>
          {this.props.data.map((athlete) => (
            <Table.Row
              key={athlete.id}
              className="athlete_clickable"
              onClick={() => this.props.showAthleteCard(true, athlete.id)}
            >
              <Table.Cell>
                <div className="little_pic">
                  {athlete.isLeader ? (
                    athlete.photo ? (
                      <div
                        className={
                          athlete.isLeader
                            ? `athlete_picture-container athlete_picture-container--${this.props.shirt}`
                            : "athlete_picture-container"
                        }
                      >
                        <img
                          className="athlete_picture"
                          alt={athlete.family_name}
                          title={athlete.family_name + " " + athlete.given_name}
                          src={athlete.photo}
                        ></img>
                      </div>
                    ) : (
                      <div
                        className={
                          athlete.isLeader
                            ? `athlete_picture-container athlete_picture-container--empty athlete_picture-container--${this.props.shirt}`
                            : "athlete_picture-container athlete_picture-container--empty"
                        }
                      >
                        <span className="athlete_picture"></span>
                      </div>
                    )
                  ) : (
                    <span
                      className={
                        athlete.isLeader
                          ? `score_place score_place--${this.props.shirt}`
                          : "score_place"
                      }
                    >
                      {athlete.ranking}
                    </span>
                  )}
                </div>
              </Table.Cell>
              <Table.Cell textAlign="center">
                {athlete.last_ranking && (
                  <>
                    {athlete.last_ranking > athlete.ranking && (
                      <span className="up_score score">
                        <Icon name="long arrow alternate up" />
                        <span className="up_score-score">
                          {athlete.last_ranking - athlete.ranking}
                        </span>
                      </span>
                    )}
                    {athlete.last_ranking < athlete.ranking && (
                      <span className="down_score score">
                        <Icon name="long arrow alternate down" />
                        <span className="down_score-score">
                          {athlete.last_ranking - athlete.ranking}
                        </span>
                      </span>
                    )}
                    {athlete.last_ranking === athlete.ranking && (
                      <span className="equal_score score">=</span>
                    )}
                  </>
                )}
                {athlete.last_ranking === null && (
                  <span className="equal_score score">+</span>
                )}
              </Table.Cell>
              <Table.Cell>
                {COUNTRY_CODES_CONVERSION[athlete.nationality] && (
                  <img
                    src={`static/img/${
                      COUNTRY_CODES_CONVERSION[athlete.nationality]["code"]
                    }.png`}
                    alt={COUNTRY_CODES_CONVERSION[athlete.nationality]["name"]}
                  />
                )}
                {/*<ReactCountryFlag
                  countryCode={COUNTRY_CODES_CONVERSION[athlete.nationality]}
                  svg
                  style={{
                    width: "2.5em",
                    height: "2.5em",
                  }}
                  title={athlete.nationality}
                />*/}
              </Table.Cell>
              {/*<Table.Cell>
                {athlete.photo ? (
                  <div
                    className={
                      athlete.isLeader
                        ? `athlete_picture-container athlete_picture-container--${this.props.shirt}`
                        : "athlete_picture-container"
                    }
                  >
                    <img
                      className="athlete_picture"
                      alt={athlete.family_name}
                      title={athlete.family_name + " " + athlete.given_name}
                      src={athlete.photo}
                    ></img>
                  </div>
                ) : (
                  <div className="athlete_picture-container athlete_picture-container--empty">
                    <span className="athlete_picture"></span>
                  </div>
                )}
                </Table.Cell>*/}
              <Table.Cell>
                <Header as="h3">
                  {athlete.family_name} {athlete.given_name}
                  {athlete.isu25 && <span className="u25_span">U25</span>}
                </Header>
              </Table.Cell>
              <Table.Cell>
                <span className="score_points_time">
                  {athlete.total_points}
                </span>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }
}
