export const API_BASE_URL = "/api";
export const PUBLIC_URL = "";
export const COUNTRY_CODES_CONVERSION = {
  AFG: { code: "AF", name: "Afghanistan" },
  ALB: { code: "AL", name: "Albania" },
  DZA: { code: "DZ", name: "Algeria" },
  ASM: { code: "AS", name: "American Samoa" },
  AND: { code: "AD", name: "Andorra" },
  AGO: { code: "AO", name: "Angola" },
  AIA: { code: "AI", name: "Anguilla" },
  ATA: { code: "AQ", name: "Antarctica" },
  ATG: { code: "AG", name: "Antigua and Barbuda" },
  ARG: { code: "AR", name: "Argentina" },
  ARM: { code: "AM", name: "Armenia" },
  ABW: { code: "AW", name: "Aruba" },
  AUS: { code: "AU", name: "Australia" },
  AUT: { code: "AT", name: "Austria" },
  AZE: { code: "AZ", name: "Azerbaijan" },
  BHS: { code: "BS", name: "Bahamas (the)" },
  BHR: { code: "BH", name: "Bahrain" },
  BGD: { code: "BD", name: "Bangladesh" },
  BRB: { code: "BB", name: "Barbados" },
  BLR: { code: "BY", name: "Belarus" },
  BEL: { code: "BE", name: "Belgium" },
  BLZ: { code: "BZ", name: "Belize" },
  BEN: { code: "BJ", name: "Benin" },
  BMU: { code: "BM", name: "Bermuda" },
  BTN: { code: "BT", name: "Bhutan" },
  BOL: { code: "BO", name: "Bolivia (Plurinational State of)" },
  BES: { code: "BQ", name: "Bonaire, Sint Eustatius and Saba" },
  BIH: { code: "BA", name: "Bosnia and Herzegovina" },
  BWA: { code: "BW", name: "Botswana" },
  BVT: { code: "BV", name: "Bouvet Island" },
  BRA: { code: "BR", name: "Brazil" },
  IOT: { code: "IO", name: "British Indian Ocean Territory (the)" },
  BRN: { code: "BN", name: "Brunei Darussalam" },
  BGR: { code: "BG", name: "Bulgaria" },
  BUL: { code: "BG", name: "Bulgaria" },
  BFA: { code: "BF", name: "Burkina Faso" },
  BDI: { code: "BI", name: "Burundi" },
  CPV: { code: "CV", name: "Cabo Verde" },
  KHM: { code: "KH", name: "Cambodia" },
  CMR: { code: "CM", name: "Cameroon" },
  CAN: { code: "CA", name: "Canada" },
  CYM: { code: "KY", name: "Cayman Islands (the)" },
  CAF: { code: "CF", name: "Central African Republic (the)" },
  TCD: { code: "TD", name: "Chad" },
  CHL: { code: "CL", name: "Chile" },
  CHI: { code: "CL", name: "Chile" },
  CHN: { code: "CN", name: "China" },
  CXR: { code: "CX", name: "Christmas Island" },
  CCK: { code: "CC", name: "Cocos (Keeling) Islands (the)" },
  COL: { code: "CO", name: "Colombia" },
  COM: { code: "KM", name: "Comoros (the)" },
  COD: { code: "CD", name: "Congo (the Democratic Republic of the)" },
  COG: { code: "CG", name: "Congo (the)" },
  COK: { code: "CK", name: "Cook Islands (the)" },
  CRI: { code: "CR", name: "Costa Rica" },
  HRV: { code: "HR", name: "Croatia" },
  CRO: { code: "HR", name: "Croatia" },
  CUB: { code: "CU", name: "Cuba" },
  CUW: { code: "CW", name: "Curaçao" },
  CYP: { code: "CY", name: "Cyprus" },
  CZE: { code: "CZ", name: "Czechia" },
  CIV: { code: "CI", name: "Côte d'Ivoire" },
  DNK: { code: "DK", name: "Denmark" },
  DJI: { code: "DJ", name: "Djibouti" },
  DMA: { code: "DM", name: "Dominica" },
  DOM: { code: "DO", name: "Dominican Republic (the)" },
  ECU: { code: "EC", name: "Ecuador" },
  EGY: { code: "EG", name: "Egypt" },
  SLV: { code: "SV", name: "El Salvador" },
  GNQ: { code: "GQ", name: "Equatorial Guinea" },
  ERI: { code: "ER", name: "Eritrea" },
  EST: { code: "EE", name: "Estonia" },
  SWZ: { code: "SZ", name: "Eswatini" },
  ETH: { code: "ET", name: "Ethiopia" },
  FLK: { code: "FK", name: "Falkland Islands (the) [Malvinas]" },
  FRO: { code: "FO", name: "Faroe Islands (the)" },
  FJI: { code: "FJ", name: "Fiji" },
  FIN: { code: "FI", name: "Finland" },
  FRA: { code: "FR", name: "France" },
  GUF: { code: "GF", name: "French Guiana" },
  PYF: { code: "PF", name: "French Polynesia" },
  ATF: { code: "TF", name: "French Southern Territories (the)" },
  GAB: { code: "GA", name: "Gabon" },
  GMB: { code: "GM", name: "Gambia (the)" },
  GEO: { code: "GE", name: "Georgia" },
  DEU: { code: "DE", name: "Germany" },
  GER: { code: "DE", name: "Germany" },
  GHA: { code: "GH", name: "Ghana" },
  GIB: { code: "GI", name: "Gibraltar" },
  GRC: { code: "GR", name: "Greece" },
  GRE: { code: "GR", name: "Greece" },
  GRL: { code: "GL", name: "Greenland" },
  GRD: { code: "GD", name: "Grenada" },
  GLP: { code: "GP", name: "Guadeloupe" },
  GUM: { code: "GU", name: "Guam" },
  GTM: { code: "GT", name: "Guatemala" },
  GGY: { code: "GG", name: "Guernsey" },
  GIN: { code: "GN", name: "Guinea" },
  GNB: { code: "GW", name: "Guinea-Bissau" },
  GUY: { code: "GY", name: "Guyana" },
  HTI: { code: "HT", name: "Haiti" },
  HMD: { code: "HM", name: "Heard Island and McDonald Islands" },
  VAT: { code: "VA", name: "Holy See (the)" },
  HND: { code: "HN", name: "Honduras" },
  HKG: { code: "HK", name: "Hong Kong" },
  HUN: { code: "HU", name: "Hungary" },
  ISL: { code: "IS", name: "Iceland" },
  IND: { code: "IN", name: "India" },
  IDN: { code: "ID", name: "Indonesia" },
  IRN: { code: "IR", name: "Iran (Islamic Republic of)" },
  IRQ: { code: "IQ", name: "Iraq" },
  IRL: { code: "IE", name: "Ireland" },
  IMN: { code: "IM", name: "Isle of Man" },
  ISR: { code: "IL", name: "Israel" },
  ITA: { code: "IT", name: "Italy" },
  JAM: { code: "JM", name: "Jamaica" },
  JPN: { code: "JP", name: "Japan" },
  JEY: { code: "JE", name: "Jersey" },
  JOR: { code: "JO", name: "Jordan" },
  KAZ: { code: "KZ", name: "Kazakhstan" },
  KEN: { code: "KE", name: "Kenya" },
  KIR: { code: "KI", name: "Kiribati" },
  PRK: { code: "KP", name: "Korea (the Democratic People's Republic of)" },
  KOR: { code: "KR", name: "Korea (the Republic of)" },
  KWT: { code: "KW", name: "Kuwait" },
  KGZ: { code: "KG", name: "Kyrgyzstan" },
  LAO: { code: "LA", name: "Lao People's Democratic Republic (the)" },
  LVA: { code: "LV", name: "Latvia" },
  LAT: { code: "LV", name: "Latvia" },
  LBN: { code: "LB", name: "Lebanon" },
  LSO: { code: "LS", name: "Lesotho" },
  LBR: { code: "LR", name: "Liberia" },
  LBY: { code: "LY", name: "Libya" },
  LIE: { code: "LI", name: "Liechtenstein" },
  LTU: { code: "LT", name: "Lithuania" },
  LUX: { code: "LU", name: "Luxembourg" },
  MAC: { code: "MO", name: "Macao" },
  MDG: { code: "MG", name: "Madagascar" },
  MWI: { code: "MW", name: "Malawi" },
  MYS: { code: "MY", name: "Malaysia" },
  MDV: { code: "MV", name: "Maldives" },
  MLI: { code: "ML", name: "Mali" },
  MLT: { code: "MT", name: "Malta" },
  MHL: { code: "MH", name: "Marshall Islands (the)" },
  MTQ: { code: "MQ", name: "Martinique" },
  MRT: { code: "MR", name: "Mauritania" },
  MUS: { code: "MU", name: "Mauritius" },
  MYT: { code: "YT", name: "Mayotte" },
  MEX: { code: "MX", name: "Mexico" },
  FSM: { code: "FM", name: "Micronesia (Federated States of)" },
  MDA: { code: "MD", name: "Moldova (the Republic of)" },
  MCO: { code: "MC", name: "Monaco" },
  MNG: { code: "MN", name: "Mongolia" },
  MGL: { code: "MN", name: "Mongolia" },
  MNE: { code: "ME", name: "Montenegro" },
  MSR: { code: "MS", name: "Montserrat" },
  MAR: { code: "MA", name: "Morocco" },
  MOZ: { code: "MZ", name: "Mozambique" },
  MMR: { code: "MM", name: "Myanmar" },
  NAM: { code: "NA", name: "Namibia" },
  NRU: { code: "NR", name: "Nauru" },
  NPL: { code: "NP", name: "Nepal" },
  NLD: { code: "NL", name: "Netherlands (the)" },
  NED: { code: "NL", name: "Netherlands (the)" },
  NCL: { code: "NC", name: "New Caledonia" },
  NZL: { code: "NZ", name: "New Zealand" },
  NIC: { code: "NI", name: "Nicaragua" },
  NER: { code: "NE", name: "Niger (the)" },
  NGA: { code: "NG", name: "Nigeria" },
  NIU: { code: "NU", name: "Niue" },
  NFK: { code: "NF", name: "Norfolk Island" },
  MNP: { code: "MP", name: "Northern Mariana Islands (the)" },
  NOR: { code: "NO", name: "Norway" },
  OMN: { code: "OM", name: "Oman" },
  PAK: { code: "PK", name: "Pakistan" },
  PLW: { code: "PW", name: "Palau" },
  PSE: { code: "PS", name: "Palestine, State of" },
  PAN: { code: "PA", name: "Panama" },
  PNG: { code: "PG", name: "Papua New Guinea" },
  PRY: { code: "PY", name: "Paraguay" },
  PER: { code: "PE", name: "Peru" },
  PHL: { code: "PH", name: "Philippines (the)" },
  PCN: { code: "PN", name: "Pitcairn" },
  POL: { code: "PL", name: "Poland" },
  PRT: { code: "PT", name: "Portugal" },
  PRI: { code: "PR", name: "Puerto Rico" },
  QAT: { code: "QA", name: "Qatar" },
  MKD: { code: "MK", name: "Republic of North Macedonia" },
  ROU: { code: "RO", name: "Romania" },
  RUS: { code: "RU", name: "Russian Federation (the)" },
  RWA: { code: "RW", name: "Rwanda" },
  REU: { code: "RE", name: "Réunion" },
  BLM: { code: "BL", name: "Saint Barthélemy" },
  SHN: { code: "SH", name: "Saint Helena, Ascension and Tristan da Cunha" },
  KNA: { code: "KN", name: "Saint Kitts and Nevis" },
  LCA: { code: "LC", name: "Saint Lucia" },
  MAF: { code: "MF", name: "Saint Martin (French part)" },
  SPM: { code: "PM", name: "Saint Pierre and Miquelon" },
  VCT: { code: "VC", name: "Saint Vincent and the Grenadines" },
  WSM: { code: "WS", name: "Samoa" },
  SMR: { code: "SM", name: "San Marino" },
  STP: { code: "ST", name: "Sao Tome and Principe" },
  SAU: { code: "SA", name: "Saudi Arabia" },
  SEN: { code: "SN", name: "Senegal" },
  SRB: { code: "RS", name: "Serbia" },
  SYC: { code: "SC", name: "Seychelles" },
  SLE: { code: "SL", name: "Sierra Leone" },
  SGP: { code: "SG", name: "Singapore" },
  SXM: { code: "SX", name: "Sint Maarten (Dutch part)" },
  SVK: { code: "SK", name: "Slovakia" },
  SVN: { code: "SI", name: "Slovenia" },
  SLO: { code: "SI", name: "Slovenia" },
  SLB: { code: "SB", name: "Solomon Islands" },
  SOM: { code: "SO", name: "Somalia" },
  ZAF: { code: "ZA", name: "South Africa" },
  SGS: { code: "GS", name: "South Georgia and the South Sandwich Islands" },
  SSD: { code: "SS", name: "South Sudan" },
  ESP: { code: "ES", name: "Spain" },
  LKA: { code: "LK", name: "Sri Lanka" },
  SDN: { code: "SD", name: "Sudan (the)" },
  SUR: { code: "SR", name: "Suriname" },
  SJM: { code: "SJ", name: "Svalbard and Jan Mayen" },
  SWE: { code: "SE", name: "Sweden" },
  CHE: { code: "CH", name: "Switzerland" },
  SUI: { code: "CH", name: "Switzerland" },
  SYR: { code: "SY", name: "Syrian Arab Republic" },
  TWN: { code: "TW", name: "Taiwan (Province of China)" },
  TJK: { code: "TJ", name: "Tajikistan" },
  TZA: { code: "TZ", name: "Tanzania, United Republic of" },
  THA: { code: "TH", name: "Thailand" },
  TLS: { code: "TL", name: "Timor-Leste" },
  TGO: { code: "TG", name: "Togo" },
  TKL: { code: "TK", name: "Tokelau" },
  TON: { code: "TO", name: "Tonga" },
  TTO: { code: "TT", name: "Trinidad and Tobago" },
  TUN: { code: "TN", name: "Tunisia" },
  TUR: { code: "TR", name: "Turkey" },
  TKM: { code: "TM", name: "Turkmenistan" },
  TCA: { code: "TC", name: "Turks and Caicos Islands (the)" },
  TUV: { code: "TV", name: "Tuvalu" },
  UGA: { code: "UG", name: "Uganda" },
  UKR: { code: "UA", name: "Ukraine" },
  ARE: { code: "AE", name: "United Arab Emirates (the)" },
  GBR: {
    code: "GB",
    name: "United Kingdom of Great Britain and Northern Ireland (the)",
  },
  UMI: { code: "UM", name: "United States Minor Outlying Islands (the)" },
  USA: { code: "US", name: "United States of America (the)" },
  URY: { code: "UY", name: "Uruguay" },
  UZB: { code: "UZ", name: "Uzbekistan" },
  VUT: { code: "VU", name: "Vanuatu" },
  VEN: { code: "VE", name: "Venezuela (Bolivarian Republic of)" },
  VNM: { code: "VN", name: "Viet Nam" },
  VGB: { code: "VG", name: "Virgin Islands (British)" },
  VIR: { code: "VI", name: "Virgin Islands (U.S.)" },
  WLF: { code: "WF", name: "Wallis and Futuna" },
  ESH: { code: "EH", name: "Western Sahara" },
  YEM: { code: "YE", name: "Yemen" },
  ZMB: { code: "ZM", name: "Zambia" },
  ZWE: { code: "ZW", name: "Zimbabwe" },
  ALA: { code: "AX", name: "Åland Islands" },
};

export const WEATHER_ICONS = {
  Overcast: "wi-cloud",
  no_weather: "wi-cloud",
  Mostly_cloudy: "wi-day-cloudy",
  Drizzle: "wi-rain-mix",
  Light_snow: "wi-snow",
  Heavy_snow: "wi-snow",
  snow: "wi-snow",
  Snow: "wi-snow",
  Light_Snowfall: "wi-snow",
  Partly_cloudy: "wi-cloudy",
  Party_cloudy: "wi-cloudy",
  Sunny: "wi-day-sunny",
  Low_level_cloud: "wi-fog",
  Sunny_intervals: "wi-day-cloudy",
  Few_clouds: "wi-day-cloudy",
  Clear_sky: "wi-day-sunny",
  Clear: "wi-day-sunny",
  Light_rain: "wi-hail",
  Rain_snow: "wi-sleet",
  Sky_Clear: "wi-day-cloudy",
  Clear_Night: "wi-night-clear",
  Mostly_cloudy_night: "wi-night-alt-cloudy",
  Partly_cloudy_night: "wi-night-alt-partly-cloudy",
  Wind_Rain: "wi-rain-wind",
  Wind_Snow: "wi-snow-wind",
  Fog: "wi-fog",
};
//export const PUBLIC_URL = process.env.PUBLIC_URL || "";
