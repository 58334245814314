import React from "react";
import { withRouter } from "react-router-dom";
import {
  Loader,
  Icon,
  Grid,
  Header,
  Segment,
  Table,
  Message,
  Progress,
  Dropdown,
  Button,
} from "semantic-ui-react";
import { COUNTRY_CODES_CONVERSION } from "../utils/constants";
import ApiService from "../utils/apiService";
import { AthleteResultsGraph } from "./AthleteResultsGraph";
import { AthleteTrendGraph } from "./AthleteTrendGraph";
import axios from "axios";

const CancelToken = axios.CancelToken;

const options = [
  { key: "All", text: "All Disciplines", value: "all" },
  { key: "Sprint", text: "Sprint", value: "Sprint" },
  { key: "Pursuit", text: "Pursuit", value: "Pursuit" },
  { key: "SprintPursuit", text: "Sprint + Pursuit", value: "Sprint+Pursuit" },
  { key: "Mass-Start", text: "Mass-Start", value: "Mass-Start" },
  { key: "Individual", text: "Individual", value: "Individual" },
];

class AthleteCardNC extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      shooting_loading: true,
      shooting_error: false,
      shooting_data: [],
      skiing_loading: true,
      skiing_error: false,
      skiing_data: [],
      filtered_disciplines: "all",
    };
  }

  handleFilteredDiscipline = (event, data) => {
    this.setState({ filtered_disciplines: data.value });
  };

  fetchDatas = async (source) => {
    this.setState({ skiing_loading: true });
    try {
      const { data } = await ApiService.getSkiingAverage(
        this.props.season,
        this.props.gender,
        source
      );

      this.setState({ skiing_data: this.add_ranking(data) });
    } catch (error) {
      this.setState({ skiing_error: true });
    }
    this.setState({ skiing_loading: false });
    this.setState({ skiing_loading: true });
    try {
      const { data } = await ApiService.getShotsAccuracy(
        this.props.season,
        this.props.gender,
        1,
        "all",
        source
      );

      this.setState({ shooting_data: this.add_ranking(data) });
    } catch (error) {
      this.setState({ shooting_error: true });
    }
    this.setState({ shooting_loading: false });
  };

  async componentDidMount() {
    const newSource = CancelToken.source();
    this.setState({ source: newSource });
    this.fetchDatas(newSource);
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.gender !== prevProps.gender ||
      this.props.season !== prevProps.season
    ) {
      const newSource = CancelToken.source();
      this.fetchDatas(newSource);
    }
  }

  add_ranking(data) {
    for (let i = 0; i < data.length; i++) {
      data[i].ranking = i + 1;
    }
    return data;
  }

  returnSkills(athlete_id, skill) {
    let percent = 0;
    if (skill === "ski") {
      const found = this.state.skiing_data.find((el) => el.id === athlete_id);
      if (found) {
        percent =
          ((this.state.skiing_data.length - found.ranking) /
            this.state.skiing_data.length) *
          100;
      }
    } else {
      const found = this.state.shooting_data.find((el) => el.id === athlete_id);
      if (found) {
        percent =
          ((this.state.shooting_data.length - found.ranking) /
            this.state.shooting_data.length) *
          100;
      }
    }
    return percent;
  }

  drawStars(number) {
    switch (number) {
      case 1:
        return (
          <span className="trophy_gold">
            <Icon name="certificate" aria-label="1" title="1" />
          </span>
        );
      case 2:
        return (
          <span className="trophy_silver">
            <Icon name="certificate" aria-label="1" title="1" />
          </span>
        );
      case 3:
        return (
          <span className="trophy_bronze">
            <Icon name="certificate" aria-label="1" title="1" />
          </span>
        );
      default:
        return number;
    }
  }

  highchartsData(data) {
    let categories = [];
    let series_data = [];
    for (let single_data of data.results) {
      categories.push(single_data.race.race_date);
      if (single_data.position === 1000) {
        series_data.push(null);
      } else {
        series_data.push({
          y: single_data.position,
          place: single_data.race.event.short_description,
          discipline: single_data.race.discipline.name,
        });
      }
    }
    let highchartsData = [
      {
        categories: categories,
        series: [
          {
            id: data.ibu_id,
            name: data.given_name + " " + data.family_name,
            data: series_data,
          },
        ],
      },
    ];
    return highchartsData;
  }

  highchartsTrendData(data) {
    let categories = [];
    let series_data_shoot = [];
    let series_data_ski = [];
    for (let single_data of data.results) {
      categories.push(single_data.race.race_date);

      /*SHOOT*/

      /*SKI*/

      if (single_data.position === 1000) {
        series_data_shoot.push(null);
        series_data_ski.push(null);
      } else {
        if (single_data.time_course) {
          let single_data_array = single_data.time_course.split(":");
          if (single_data_array.length === 2) {
            const minutes = parseFloat(single_data_array[0]) * 60;
            const seconds = parseFloat(single_data_array[1]);
            series_data_ski.push(minutes + seconds);
          } else {
            series_data_ski.push(parseFloat(single_data.time_course));
          }
        } else {
          series_data_ski.push(null);
        }
        if (single_data.shootings) {
          let shots_array = single_data.shootings.split("+");
          let shots_total = shots_array.length * 5;
          let shots_done = 0;
          for (let shot of shots_array) {
            shots_done += 5 - parseInt(shot);
          }
          let rounded = Math.round((shots_done / shots_total) * 100);
          series_data_shoot.push(rounded);
        } else {
          series_data_shoot.push(null);
        }
        /*series_data.push({
          y: single_data.position,
          place: single_data.race.event.short_description,
          discipline: single_data.race.discipline.name,
        });*/
      }
    }
    let highchartsData = {
      categories: categories,
      data_shoot: series_data_shoot,
      data_ski: series_data_ski,
    };

    return highchartsData;
  }

  filterData = (data) => {
    let filter_results = [...data.results];
    let new_data = { ...data };
    if (
      this.state.filtered_disciplines &&
      this.state.filtered_disciplines !== "all"
    ) {
      if (this.state.filtered_disciplines === "Sprint+Pursuit") {
        filter_results = data.results.filter(
          (single_result) =>
            single_result.race.discipline.name === "Sprint" ||
            single_result.race.discipline.name === "Pursuit"
        );
        new_data.results = filter_results;
      } else {
        filter_results = data.results.filter(
          (single_result) =>
            single_result.race.discipline.name ===
            this.state.filtered_disciplines
        );
        new_data.results = filter_results;
      }
    }
    return new_data;
  };

  render() {
    if (this.props.error) {
      return (
        <Message
          negative
          className="text_center"
          content="Sorry, an error occured"
        ></Message>
      );
    }
    if (this.props.loading) {
      return (
        <div className="LoaderContainer">
          <Loader active content="Loading"></Loader>
        </div>
      );
    }
    return (
      <>
        <Segment>
          <div
            className="close_athlete_modale"
            onClick={() => this.props.showAthleteCard(false, null)}
          >
            <Icon name="close"></Icon>
          </div>

          <Grid className="athletecard_display_grid">
            <Grid.Row columns={2}>
              <Grid.Column>
                {this.props.data.photo ? (
                  <div className="athlete_picture_big-container">
                    <img
                      className="athlete_picture_big"
                      alt={this.props.data.family_name}
                      title={
                        this.props.data.family_name +
                        " " +
                        this.props.data.given_name
                      }
                      src={this.props.data.photo}
                    ></img>
                  </div>
                ) : (
                  <div className="athlete_picture_big-container athlete_picture_big-container--empty">
                    <span className="athlete_picture_big"></span>
                  </div>
                )}
              </Grid.Column>
              <Grid.Column>
                <Header as="h2" textAlign="left" className="athlete_card_name">
                  {this.props.data.family_name}
                </Header>
                <Header as="h3" textAlign="left">
                  {this.props.data.given_name}
                </Header>
                <Table className="athletecard_id_table">
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>
                        <img
                          src={`static/img/${
                            COUNTRY_CODES_CONVERSION[
                              this.props.data.nationality
                            ]["code"]
                          }.png`}
                          alt={
                            COUNTRY_CODES_CONVERSION[
                              this.props.data.nationality
                            ]["name"]
                          }
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Header as="h4" textAlign="left">
                          {
                            COUNTRY_CODES_CONVERSION[
                              this.props.data.nationality
                            ]["name"]
                          }
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
                <div className="athlete_card_age">
                  {this.props.data.age}
                  {this.props.data.isu25 && (
                    <span className="u25_span">U25</span>
                  )}
                </div>
                <div>
                  <Button
                    icon
                    labelPosition="left"
                    onClick={() =>
                      this.props.history.push(`/athlete/${this.props.data.id}`)
                    }
                  >
                    <Icon name="history" />
                    HISTORY
                  </Button>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Segment>
          <div className="athlete_card_skills">
            <Header as="h3" textAlign="center">
              <span className="float-left">
                <Icon name="sort amount up" />
              </span>
              Season Skills
            </Header>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column>
                  Skiing
                  {this.returnSkills(this.props.data.id, "ski") !== 0 ? (
                    <Progress
                      percent={this.returnSkills(this.props.data.id, "ski")}
                      indicating
                      size="small"
                    />
                  ) : (
                    <Message
                      className="text_center"
                      content="No data"
                    ></Message>
                  )}
                </Grid.Column>
                <Grid.Column>
                  Shooting
                  <Progress
                    percent={this.returnSkills(this.props.data.id, "shoot")}
                    indicating
                    size="small"
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Dropdown
              placeholder="Discipline"
              fluid
              selection
              value={this.state.filtered_disciplines}
              options={options}
              disabled={this.props.loading}
              className="select_discipline"
              onChange={this.handleFilteredDiscipline}
            />
            <AthleteTrendGraph
              data={this.highchartsTrendData(this.filterData(this.props.data))}
              loading={this.props.loading}
              error={false}
              empty_datas={false}
            />
          </div>
        </Segment>
        <Segment>
          <Header as="h3" textAlign="center">
            <span className="float-left">
              <Icon name="chart bar" />
            </span>
            Season results
          </Header>
          <AthleteResultsGraph
            data={this.highchartsData(this.filterData(this.props.data))}
            loading={this.props.loading}
            error={false}
            empty_datas={false}
          />
        </Segment>

        <Table striped className="athletecard_results_table">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell>Event</Table.HeaderCell>
              <Table.HeaderCell>Race</Table.HeaderCell>
              <Table.HeaderCell>Shooting</Table.HeaderCell>
              <Table.HeaderCell className="center_text">Rank</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.filterData(this.props.data).results.map((result) => (
              <Table.Row key={result.race.race_date}>
                <Table.Cell>{result.race.race_date}</Table.Cell>
                <Table.Cell>{result.race.event.short_description}</Table.Cell>
                <Table.Cell>{result.race.discipline.name}</Table.Cell>
                <Table.Cell>
                  {result.shootings !== "" && result.shootings !== null ? (
                    result.shootings
                  ) : (
                    <span>-</span>
                  )}
                </Table.Cell>
                <Table.Cell className="center_text">
                  {result.position !== 1000
                    ? this.drawStars(result.position)
                    : result.position_comment}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </>
    );
  }
}
export const AthleteCard = withRouter(AthleteCardNC);
