import React from "react";
import { Segment, Header, Icon, Loader, Table } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import ApiService from "../utils/apiService";
import { COUNTRY_CODES_CONVERSION } from "../utils/constants";

import axios from "axios";

const CancelToken = axios.CancelToken;

class NCPrediction extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      data: [],
    };
  }

  fetchDatas = async (newsource) => {
    //all athletes
    this.setState({ loading: true, error: false });
    try {
      const { data } = await ApiService.getPredictions(newsource);
      this.setState({ data: data });
    } catch (error) {
      this.setState({ error: true });
    }
    this.setState({ loading: false });
  };

  async componentDidMount() {
    const newSource = CancelToken.source();
    this.setState({ source: newSource });
    this.fetchDatas(newSource);
  }

  render() {
    return (
      <div id="container fullheight">
        <Header as="h1" textAlign="center" className="main_header">
          <img
            src="/static/img/logo_bs.png"
            alt="biathlon stats"
            className="logo_img"
            onClick={() => this.props.history.push("/")}
          />
        </Header>

        <Segment>
          <Header as="h2" textAlign="center">
            <span className="float-left">
              <Icon name="fire" />
            </span>
            Season's predictions!
          </Header>
        </Segment>
        {this.state.loading ? (
          <div className="LoaderContainer">
            <Loader active content="Loading"></Loader>
          </div>
        ) : (
          <Table unstackable striped celled structured>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell rowSpan="2">Twitter Name</Table.HeaderCell>
                <Table.HeaderCell rowSpan="2">Points</Table.HeaderCell>
                <Table.HeaderCell colSpan="3">Women</Table.HeaderCell>
                <Table.HeaderCell colSpan="3">Men</Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>First</Table.HeaderCell>
                <Table.HeaderCell>Second</Table.HeaderCell>
                <Table.HeaderCell>Third</Table.HeaderCell>
                <Table.HeaderCell>First</Table.HeaderCell>
                <Table.HeaderCell>Second</Table.HeaderCell>
                <Table.HeaderCell>Third</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.state.data.map((athlete) => (
                <Table.Row key={athlete.twitter_codename}>
                  <Table.Cell>
                    {athlete.twitter_name} ({athlete.twitter_codename})
                  </Table.Cell>
                  <Table.Cell>{athlete.total_points}</Table.Cell>
                  <Table.Cell className={athlete.first_woman_color}>
                    {athlete.first_woman}
                  </Table.Cell>
                  <Table.Cell className={athlete.second_woman_color}>
                    {athlete.second_woman}
                  </Table.Cell>
                  <Table.Cell className={athlete.third_woman_color}>
                    {athlete.third_woman}
                  </Table.Cell>
                  <Table.Cell className={athlete.first_man_color}>
                    {athlete.first_man}
                  </Table.Cell>
                  <Table.Cell className={athlete.second_man_color}>
                    {athlete.second_man}
                  </Table.Cell>
                  <Table.Cell className={athlete.third_man_color}>
                    {athlete.third_man}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}

        <Segment vertical textAlign="center">
          <h4>
            Athlete pictures:{" "}
            <a href="https://www.biathlonworld.com/">
              https://www.biathlonworld.com/
            </a>
          </h4>
        </Segment>

        <Segment vertical textAlign="center">
          <h4>
            <a href="https://twitter.com/biathstats">
              <span className="twitter_content">
                <Icon name="twitter" />
              </span>
            </a>
          </h4>
        </Segment>
      </div>
    );
  }
}

export const PredictionPage = withRouter(NCPrediction);
