import React from "react";
import ApiService from "../utils/apiService";
import { Segment, Dropdown, Grid } from "semantic-ui-react";
import { ResultsDistribAthlete } from "../components/ResultsDistribAthlete";
import axios from "axios";

const options = [
  { key: "All", text: "All Disciplines", value: "all" },
  { key: "Sprint", text: "Sprint", value: "SP" },
  { key: "Pursuit", text: "Pursuit", value: "PU" },
  { key: "Mass-Start", text: "Mass-Start", value: "MS" },
  { key: "Individual", text: "Individual", value: "IN" },
];

const CancelToken = axios.CancelToken;

export class ResultsDistribAthleteContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      data: [],
      empty_datas: true,
      selected_discipline: "all",
      selected_season: "all",
      seasons_options: [{ key: "All", text: "All Seasons", value: "all" }],
    };
  }

  handleFilteredDiscipline = (event, data) => {
    this.setState({ selected_discipline: data.value });
    this.fetchDatas(
      this.props.athlete_id,
      data.value,
      this.state.selected_season,
      this.state.source
    );
  };
  handleFilteredSeason = (event, data) => {
    this.setState({ selected_season: data.value });
    this.fetchDatas(
      this.props.athlete_id,
      this.state.selected_discipline,
      data.value,
      this.state.source
    );
  };

  pieTransform(data) {
    let new_data = [
      {
        name: "Nb. races",
        colorByPoint: true,
        data: [
          { name: "Gold", y: data.gold, color: "#e8b318" },
          { name: "Silver", y: data.silver, color: "#a9a9a9" },
          { name: "Bronze", y: data.bronze, color: "#cd7f32" },
          { name: "Flowers", y: data.flowers, color: "rgba(52,152,219,1)" },
          { name: "7th to 10th", y: data.top10, color: "rgba(52,152,219,0.5)" },
          {
            name: "11th to 20th",
            y: data.top20,
            color: "rgba(52,152,219,0.4)",
          },
          {
            name: ">20th in points",
            y: data.upto20,
            color: "rgba(52,152,219,0.3)",
          },
          { name: "Out of points", y: data.out, color: "rgba(52,152,219,0.1)" },
        ],
      },
    ];
    return new_data;
  }

  isEmptyData(datas) {
    let is_empty = false;
    if (datas[0].data.length === 0) {
      is_empty = true;
    }
    return is_empty;
  }

  fetchDatas = async (athlete_id, race, season, source) => {
    this.setState({ loading: true, error: false });
    try {
      const { data } = await ApiService.getResultsDistributionAthlete(
        athlete_id,
        race,
        season,
        source
      );

      let all_seasons = [{ key: "All", text: "All Seasons", value: "all" }];
      for (season of data.seasons) {
        all_seasons.push({ key: season[1], text: season[0], value: season[1] });
        this.setState({ data: data, seasons_options: all_seasons });
      }
    } catch (error) {
      if (error.message !== "gender change") {
        this.setState({ error: true });
      }
    }
    this.setState({ loading: false });
  };

  async componentDidMount() {
    const newSource = CancelToken.source();
    this.setState({ source: newSource });
    this.fetchDatas(
      this.props.athlete_id,
      this.state.selected_discipline,
      this.state.selected_season,
      newSource
    );
  }
  async componentDidUpdate(prevProps, prevState) {
    if (this.props.athlete_id !== prevProps.athlete_id) {
      ApiService.cancelFetch(this.state.source);

      const newSource = CancelToken.source();
      this.setState({ data: [], source: newSource });
      this.fetchDatas(
        this.props.athlete_id,
        this.state.selected_discipline,
        this.state.selected_season,
        newSource
      );
    }
  }

  render() {
    return (
      <>
        <Grid columns="equal" className="noborder">
          <Grid.Row>
            <Grid.Column>
              <Dropdown
                placeholder="Discipline"
                fluid
                selection
                value={this.state.selected_discipline}
                options={options}
                disabled={this.state.loading}
                loading={this.state.loading}
                onChange={this.handleFilteredDiscipline}
              />
            </Grid.Column>
            <Grid.Column>
              <Dropdown
                placeholder="Season"
                fluid
                selection
                value={this.state.selected_season}
                options={this.state.seasons_options}
                disabled={this.state.loading}
                loading={this.state.loading}
                onChange={this.handleFilteredSeason}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Segment className="noborder"></Segment>

        <ResultsDistribAthlete
          data={this.pieTransform(this.state.data)}
          loading={this.state.loading}
          error={this.state.error}
        />
      </>
    );
  }
}
