import React from "react";
import ApiService from "../utils/apiService";
import { CurrentTrends } from "../components/CurrentTrends";
import { Message, Loader, Dropdown } from "semantic-ui-react";
import axios from "axios";

const CancelToken = axios.CancelToken;

const options = [
  { key: 1, text: "1 race trends (last race vs race before)", value: 1 },
  { key: 2, text: "2 races trends (2 last races vs 2 races before)", value: 2 },
  { key: 3, text: "3 races trends (3 last races vs 3 races before)", value: 3 },
  { key: 4, text: "4 races trends (4 last races vs 4 races before)", value: 4 },
  { key: 5, text: "5 races trends (5 last races vs 5 races before)", value: 5 },
];

export class CurrentTrendsContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      count: 3,
      data: [],
      column: "diff_position",
      direction: "descending",
    };
  }

  //arrow to bind this
  handleSort = (clickedColumn) => () => {
    const { column, data, direction } = this.state;
    let newdata = [...data];

    if (column !== clickedColumn) {
      newdata = newdata.sort((a, b) => a[clickedColumn] - b[clickedColumn]);
      newdata = newdata.reverse();
      this.setState({
        column: clickedColumn,
        data: newdata,
        direction: "descending",
      });
      return;
    } else {
      newdata = newdata.reverse();
      this.setState({
        data: newdata,
        direction: direction === "ascending" ? "descending" : "ascending",
      });
    }
  };

  handleCountTrends = (event, data) => {
    this.setState({
      count: data.value,
      column: "diff_position",
      direction: "descending",
    });
    this.fetchDatas(this.state.source, data.value);
  };

  filter_datas(data, filters, filter_countries) {
    let newdata = data;
    if (filters.length > 0 || filter_countries.length > 0) {
      newdata = data.filter(
        (singledata) =>
          filters.includes(singledata.id) ||
          filter_countries.includes(singledata.nationality)
      );
    }
    return newdata;
  }

  fetchDatas = async (source, count) => {
    this.setState({ loading: true, error: false });
    try {
      const { data } = await ApiService.getTrends(
        count,
        this.props.season,
        this.props.gender,
        source
      );
      this.setState({ data: data });
      console.log(data);
    } catch (error) {
      if (error.message !== "gender change") {
        this.setState({ error: true });
      }
    }
    this.setState({ loading: false });
  };

  async componentDidMount() {
    const newSource = CancelToken.source();
    this.setState({ source: newSource });
    this.fetchDatas(newSource, this.state.count);
  }
  async componentDidUpdate(prevProps) {
    if (
      this.props.gender !== prevProps.gender ||
      this.props.season !== prevProps.season
    ) {
      ApiService.cancelFetch(this.state.source);
      const newSource = CancelToken.source();
      this.setState({ source: newSource, loading: true });
      this.fetchDatas(newSource, this.state.count);
    }
  }

  add_ranking(data, direction) {
    let newdata = [...data];
    if (direction === "descending") {
      for (let i = 0; i < newdata.length; i++) {
        newdata[i].ranking = i + 1;
      }
    } else {
      for (let i = 0; i < newdata.length; i++) {
        newdata[i].ranking = newdata.length - i;
      }
    }

    return newdata;
  }

  render() {
    if (this.state.error) {
      return (
        <Message
          negative
          className="text_center"
          content="Sorry, an error occured"
        ></Message>
      );
    }
    if (this.state.loading) {
      return (
        <div className="LoaderContainer">
          <Loader active content="Loading"></Loader>
        </div>
      );
    }
    if (this.state.data.length === 0) {
      return (
        <Message className="text_center" content="No data to display"></Message>
      );
    }
    return (
      <>
        <div className="marginleftright">
          <Dropdown
            placeholder="All shootings"
            fluid
            selection
            value={this.state.count}
            options={options}
            disabled={this.props.loading}
            onChange={this.handleCountTrends}
          />
        </div>

        <CurrentTrends
          data={this.filter_datas(
            this.add_ranking(this.state.data, this.state.direction),
            this.props.filtered,
            this.props.filtered_countries
          )}
          showAthleteCard={this.props.showAthleteCard}
          handleSort={this.handleSort}
          direction={this.state.direction}
          column={this.state.column}
          error={this.state.error}
        />
      </>
    );
  }
}
