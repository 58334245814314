import React from "react";
import { Checkbox, Dropdown, Segment } from "semantic-ui-react";
import ApiService from "../utils/apiService";
import { ShotsAccuracy } from "../components/ShotsAccuracy";
import axios from "axios";

const CancelToken = axios.CancelToken;

const options = [
  { key: "All", text: "All Shootings", value: "all" },
  { key: "Prone", text: "Prone shootings", value: "prone" },
  { key: "Standing", text: "Standing shootings", value: "standing" },
];

export class ShotsAccuracyContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      data: [],
      scratchCode: 1,
      selected_shooting: "all",
    };
  }

  handleFilteredShootings = (event, data) => {
    this.setState({ selected_shooting: data.value });
    this.fetchDatas(
      this.props.gender,
      this.props.season,
      this.state.source,
      data.value,
      this.state.scratchCode
    );
  };

  filter_datas(data, filters, filter_countries) {
    let newdata = data;
    if (filters.length > 0 || filter_countries.length > 0) {
      newdata = data.filter(
        (data) =>
          filters.includes(data.id) ||
          filter_countries.includes(data.nationality)
      );
    }
    return newdata;
  }
  add_ranking(data) {
    for (let i = 0; i < data.length; i++) {
      if (i === 0) {
        data[i].ranking = 1;
      } else {
        if (
          data[i].shots_accuracy === data[i - 1].shots_accuracy &&
          data[i].good_shots === data[i - 1].good_shots
        ) {
          data[i].ranking = data[i - 1].ranking;
        } else {
          data[i].ranking = i + 1;
        }
      }

      let isLeader = false;
      if (
        i === 0 ||
        (data[i].shots_accuracy === data[0].shots_accuracy &&
          data[i].good_shots === data[0].good_shots)
      ) {
        isLeader = true;
      }
      data[i].isLeader = isLeader;
    }
    return data;
  }

  fetchDatas = async (gender, season, source, shootingtype, scratch) => {
    this.setState({ loading: true, error: false });
    try {
      const { data } = await ApiService.getShotsAccuracy(
        this.props.season,
        this.props.gender,
        scratch,
        shootingtype,
        source
      );
      if (gender === this.props.gender && season === this.props.season) {
        this.setState({ data: data });
      }
    } catch (error) {
      if (
        gender === this.props.gender &&
        season === this.props.season &&
        error.message !== "gender change"
      ) {
        this.setState({ error: true });
      }
    }
    if (gender === this.props.gender && season === this.props.season) {
      this.setState({ loading: false });
    }
  };

  handleScratchChange = (value) => {
    this.setState({ scratchStatus: value });
    if (value) {
      const newSource = CancelToken.source();
      this.setState({ source: newSource, scratchCode: 2 });
      this.fetchDatas(
        this.props.gender,
        this.props.season,
        newSource,
        this.state.selected_shooting,
        2
      );
    } else {
      const newSource = CancelToken.source();
      this.setState({ source: newSource, scratchCode: 1 });
      this.fetchDatas(
        this.props.gender,
        this.props.season,
        newSource,
        this.state.selected_shooting,
        1
      );
    }
  };

  async componentDidMount() {
    const newSource = CancelToken.source();
    this.setState({ source: newSource });
    this.fetchDatas(
      this.props.gender,
      this.props.season,
      newSource,
      this.state.selected_shooting,
      1
    );
  }
  async componentDidUpdate(prevProps) {
    if (
      this.props.gender !== prevProps.gender ||
      this.props.season !== prevProps.season
    ) {
      if (this.state.loading) {
        ApiService.cancelFetch(this.state.source);
      }
      const newSource = CancelToken.source();
      this.setState({ data: [], source: newSource });
      this.fetchDatas(
        this.props.gender,
        this.props.season,
        newSource,
        this.state.selected_shooting,
        this.state.scratchCode
      );
    }
  }

  render() {
    return (
      <>
        <div className="AbsoluteSlider">
          <span> {"hide < 1/2 races"}</span>
          <Checkbox
            slider
            checked={this.state.scratchStatus}
            onChange={(e, data) => this.handleScratchChange(data.checked)}
          />
        </div>
        <Segment className="noborder">
          <Dropdown
            placeholder="Shootings type"
            fluid
            selection
            value={this.state.selected_shooting}
            options={options}
            disabled={this.props.loading}
            onChange={this.handleFilteredShootings}
          />
        </Segment>
        <ShotsAccuracy
          data={this.filter_datas(
            this.add_ranking(this.state.data),
            this.props.filtered,
            this.props.filtered_countries
          )}
          loading={this.state.loading}
          error={this.state.error}
          showAthleteCard={this.props.showAthleteCard}
        />
      </>
    );
  }
}
