import React from "react";
import {
  Grid,
  Segment,
  Header,
  Icon,
  Dropdown,
  Loader,
  Table,
  Progress,
  Message,
  Button,
  Popup,
} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import ApiService from "../utils/apiService";
import { COUNTRY_CODES_CONVERSION } from "../utils/constants";

import { SpeedVsAccuracyAthleteContainer } from "./SpeedVsAccuracyAthleteContainer";
import { SeasonsRankingsAthleteContainer } from "./SeasonsRankingsAthleteContainer";
import { SeasonsSkiingShootingAthleteContainer } from "./SeasonsSkiingShootingAthleteContainer";
import { AllResultsAthleteContainer } from "./AllResultsAthleteContainer";
import { CleanSheetsAthleteContainer } from "./CleanSheetsAthleteContainer";
import { PointsPerRaceAthleteContainer } from "./PointsPerRaceAthleteContainer";
import { ResultsDistribAthleteContainer } from "./ResultsDistribAthleteContainer";

import axios from "axios";

const CancelToken = axios.CancelToken;

class NCAthlete extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      athlete: this.props.match.params.id,
      selected_athlete: parseInt(this.props.match.params.id),
      //gender: this.props.match.params.gender,
      temp_athlete: parseInt(this.props.match.params.id),
      gender: "MIXED",
      all_athletes: [],
      loading_all_athletes: true,
      error: false,
      current_name: "...",
      athlete_loading: true,
      athlete_error: false,
      athlete_data: [],
      btn_active: false,
    };
  }

  drawGlobes(number, color) {
    let rows = [];
    for (let i = 0; i < number; i++) {
      if (color === "gold") {
        rows.push(
          <span className="gold trophyfont" key={i}>
            <Icon name="trophy" />
          </span>
        );
      } else {
        rows.push(
          <span className="red trophyfont" key={i}>
            <Icon name="trophy" />
          </span>
        );
      }
    }
    return rows;
  }

  handleChangeAthlete = (event, data) => {
    if (parseInt(data.value) !== this.state.temp_athlete) {
      this.setState({
        temp_athlete: parseInt(data.value),
        btn_active: true,
      });
    }
  };

  handleClick = () => {
    this.props.history.push(`/athlete/${this.state.temp_athlete}`);
    /*this.setState({
      athlete_loading: true,
    });

    this.setState({
      athlete_loading: true,
      selected_athlete: parseInt(this.state.temp_athlete),
    });
    const athlete_name = this.state.all_athletes.find(
      (el) => el.value === this.state.temp_athlete
    ).text;
    this.setState({
      current_name: athlete_name,
    });
    ApiService.cancelFetch(this.state.source);
    const newSource = CancelToken.source();
    this.setState({ source: newSource });
    this.fetchAthleteDatas(newSource, this.state.temp_athlete);*/
  };

  fetchDatas = async () => {
    //all athletes
    this.setState({ loading_all_athletes: true, error: false });
    try {
      const { data } = await ApiService.getFullList(this.state.gender);
      this.setState({ all_athletes: data });
      const athlete_name = data.find(
        (el) => el.value === this.state.selected_athlete
      ).text;
      this.setState({
        current_name: athlete_name,
      });
    } catch (error) {
      if (error.message !== "gender change") {
        this.setState({ error: true });
      }
    }
    this.setState({ loading_all_athletes: false });
  };

  fetchAthleteDatas = async (source, athlete) => {
    //single athlete
    this.setState({ athlete_loading: true, athlete_error: false });
    try {
      const { data } = await ApiService.getAthleteCard(athlete, source);
      this.setState({ athlete_data: data, btn_active: false });
    } catch (error) {
      if (error.message !== "gender change") {
        this.setState({ athlete_error: true });
      }
    }
    this.setState({ athlete_loading: false });
  };

  async componentDidMount() {
    const newSource = CancelToken.source();
    this.setState({ source: newSource });
    this.fetchDatas();
    this.fetchAthleteDatas(newSource, this.state.athlete);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      ApiService.cancelFetch(this.state.source);

      const newSource = CancelToken.source();

      this.setState({
        athlete_loading: true,
        athlete: parseInt(this.props.match.params.id),
        selected_athlete: parseInt(this.props.match.params.id),
        temp_athlete: parseInt(this.props.match.params.id),
        source: newSource,
      });
      const athlete_name = this.state.all_athletes.find(
        (el) => el.value === parseInt(this.props.match.params.id)
      ).text;
      this.setState({
        current_name: athlete_name,
      });
      this.fetchAthleteDatas(newSource, this.props.match.params.id);
    }
  }

  render() {
    return (
      <div id="container">
        <Header as="h1" textAlign="center" className="main_header">
          <span
            className="float-left-back"
            onClick={() => this.props.history.push("/")}
          >
            <Icon name="close" />
          </span>
          <img
            src="/static/img/logo_bs.png"
            alt="biathlon stats"
            className="logo_img"
            onClick={() => this.props.history.push("/")}
          />

          {this.state.loading_all_athletes ? (
            <span className="name_container">
              <Loader active></Loader>
            </span>
          ) : (
            <span className="name_container">{this.state.current_name}</span>
          )}
        </Header>
        <div className="filters_area_men_single">
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column width={10}>
                <Dropdown
                  placeholder="Athlete"
                  fluid
                  selection
                  search
                  value={this.state.temp_athlete}
                  options={this.state.all_athletes}
                  disabled={this.state.loading_all_athletes}
                  onChange={this.handleChangeAthlete}
                  loading={this.state.loading_all_athletes}
                />
              </Grid.Column>
              <Grid.Column width={6}>
                <Button
                  icon
                  labelPosition="left"
                  loading={this.state.loading_all_athletes}
                  disabled={this.state.loading_all_athletes}
                  onClick={this.handleClick}
                  positive={this.state.btn_active ? true : false}
                >
                  <Icon name="redo alternate" />
                  LOAD DATAS
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <Grid stackable>
          <Grid.Row stretched columns={2}>
            <Grid.Column width={6}>
              <Segment>
                <div className="history-container">
                  {this.state.athlete_error ? (
                    <Message
                      negative
                      className="text_center"
                      content="Sorry, an error occured"
                    ></Message>
                  ) : (
                    <>
                      {this.state.athlete_loading ? (
                        <div className="LoaderContainer">
                          <Loader active content="Loading"></Loader>
                        </div>
                      ) : (
                        <>
                          <Grid className="athletecard_display_grid">
                            <Grid.Row columns={2}>
                              <Grid.Column>
                                {this.state.athlete_data.photo ? (
                                  <div className="athlete_picture_big-container">
                                    <img
                                      className="athlete_picture_big"
                                      alt={this.state.athlete_data.family_name}
                                      title={
                                        this.state.athlete_data.family_name +
                                        " " +
                                        this.state.athlete_data.given_name
                                      }
                                      src={this.state.athlete_data.photo}
                                    ></img>
                                  </div>
                                ) : (
                                  <div className="athlete_picture_big-container athlete_picture_big-container--empty">
                                    <span className="athlete_picture_big"></span>
                                  </div>
                                )}
                                {(this.state.athlete_data.mainglobe > 0 ||
                                  this.state.athlete_data.disciplineglobe >
                                    0) && (
                                  <>
                                    <span className="width100centered">
                                      <>
                                        {" "}
                                        {this.drawGlobes(
                                          this.state.athlete_data.mainglobe,
                                          "gold"
                                        )}
                                        {this.drawGlobes(
                                          this.state.athlete_data
                                            .disciplineglobe,
                                          "red"
                                        )}
                                        <br />
                                      </>
                                    </span>
                                    <br />
                                  </>
                                )}
                                <span className="width100centered">
                                  {this.state.athlete_data.age}
                                  {this.state.athlete_data.isu25 && (
                                    <span className="u25_span">U25</span>
                                  )}
                                </span>
                              </Grid.Column>
                              <Grid.Column>
                                {/*<Header
                            as="h2"
                            textAlign="left"
                            className="athlete_card_name"
                          >
                            {this.state.athlete_data.family_name}
                          </Header>
                          <Header as="h3" textAlign="left">
                            {this.state.athlete_data.given_name}
                          </Header>*/}
                                <Table className="athletecard_id_table">
                                  <Table.Body>
                                    <Table.Row>
                                      <Table.Cell>
                                        <img
                                          src={`/static/img/${
                                            COUNTRY_CODES_CONVERSION[
                                              this.state.athlete_data
                                                .nationality
                                            ]["code"]
                                          }.png`}
                                          alt={
                                            COUNTRY_CODES_CONVERSION[
                                              this.state.athlete_data
                                                .nationality
                                            ]["name"]
                                          }
                                        />
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Header as="h4" textAlign="left">
                                          {
                                            COUNTRY_CODES_CONVERSION[
                                              this.state.athlete_data
                                                .nationality
                                            ]["name"]
                                          }
                                        </Header>
                                      </Table.Cell>
                                    </Table.Row>
                                  </Table.Body>
                                </Table>
                                <div className="athlete_card_age">
                                  Skiing
                                  {this.state.athlete_data.levels.ski ? (
                                    <Progress
                                      percent={
                                        this.state.athlete_data.levels.ski
                                      }
                                      indicating
                                      size="small"
                                    />
                                  ) : (
                                    <Message
                                      className="text_center"
                                      content="No data"
                                    ></Message>
                                  )}
                                  Shooting
                                  {this.state.athlete_data.levels.shoot ? (
                                    <Progress
                                      percent={
                                        this.state.athlete_data.levels.shoot
                                      }
                                      indicating
                                      size="small"
                                    />
                                  ) : (
                                    <Message
                                      className="text_center"
                                      content="No data"
                                    ></Message>
                                  )}
                                </div>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                          {/*<Grid>
                            <Grid.Row columns={2}>
                              <Grid.Column>
                                Skiing
                                {this.state.athlete_data.levels.ski ? (
                                  <Progress
                                    percent={this.state.athlete_data.levels.ski}
                                    indicating
                                    size="small"
                                  />
                                ) : (
                                  <Message
                                    className="text_center"
                                    content="No data"
                                  ></Message>
                                )}
                              </Grid.Column>
                              <Grid.Column>
                                Shooting
                                {this.state.athlete_data.levels.shoot ? (
                                  <Progress
                                    percent={
                                      this.state.athlete_data.levels.shoot
                                    }
                                    indicating
                                    size="small"
                                  />
                                ) : (
                                  <Message
                                    className="text_center"
                                    content="No data"
                                  ></Message>
                                )}
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>*/}
                        </>
                      )}
                    </>
                  )}
                </div>
              </Segment>
            </Grid.Column>
            <Grid.Column width={10}>
              <Segment className="noborder">
                <div className="history-container">
                  {this.state.athlete_error ? (
                    <Message
                      negative
                      className="text_center"
                      content="Sorry, an error occured"
                    ></Message>
                  ) : (
                    <>
                      {this.state.athlete_loading ? (
                        <div className="LoaderContainer">
                          <Loader active content="Loading"></Loader>
                        </div>
                      ) : (
                        <Table unstackable definition>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell>
                                From seasons{" "}
                                {this.state.athlete_data.levels.season_begin} to{" "}
                                {this.state.athlete_data.levels.season_end}
                              </Table.HeaderCell>
                              <Table.HeaderCell className="centered_cell">
                                {" "}
                                <span className="trophy trophy_gold">
                                  <Icon name="certificate" />
                                </span>
                              </Table.HeaderCell>
                              <Table.HeaderCell className="centered_cell">
                                {" "}
                                <span className="trophy trophy_silver">
                                  <Icon name="certificate" />
                                </span>
                              </Table.HeaderCell>
                              <Table.HeaderCell className="centered_cell">
                                {" "}
                                <span className="trophy trophy_bronze">
                                  <Icon name="certificate" />
                                </span>
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>

                          <Table.Body>
                            <Table.Row>
                              <Table.Cell>
                                {" "}
                                <Icon name="trophy" /> IBU World Cup (without WC
                                /OG)
                              </Table.Cell>
                              <Table.Cell className="big_number centered_cell">
                                <Popup
                                  trigger={
                                    <span className="overpointer">
                                      {this.state.athlete_data.medals.ibu_gold}
                                    </span>
                                  }
                                  content={
                                    "SP: " +
                                    this.state.athlete_data.medals.ibu_details
                                      .gold.SP +
                                    " | PU: " +
                                    this.state.athlete_data.medals.ibu_details
                                      .gold.PU +
                                    " | MS: " +
                                    this.state.athlete_data.medals.ibu_details
                                      .gold.MS +
                                    " | IN: " +
                                    this.state.athlete_data.medals.ibu_details
                                      .gold.IN
                                  }
                                  position="top center"
                                />
                              </Table.Cell>
                              <Table.Cell className="big_number centered_cell">
                                <Popup
                                  trigger={
                                    <span className="overpointer">
                                      {
                                        this.state.athlete_data.medals
                                          .ibu_silver
                                      }
                                    </span>
                                  }
                                  content={
                                    "SP: " +
                                    this.state.athlete_data.medals.ibu_details
                                      .silver.SP +
                                    " | PU: " +
                                    this.state.athlete_data.medals.ibu_details
                                      .silver.PU +
                                    " | MS: " +
                                    this.state.athlete_data.medals.ibu_details
                                      .silver.MS +
                                    " | IN: " +
                                    this.state.athlete_data.medals.ibu_details
                                      .silver.IN
                                  }
                                  position="top center"
                                />
                              </Table.Cell>
                              <Table.Cell className="big_number centered_cell">
                                <Popup
                                  trigger={
                                    <span className="overpointer">
                                      {
                                        this.state.athlete_data.medals
                                          .ibu_bronze
                                      }
                                    </span>
                                  }
                                  content={
                                    "SP: " +
                                    this.state.athlete_data.medals.ibu_details
                                      .bronze.SP +
                                    " | PU: " +
                                    this.state.athlete_data.medals.ibu_details
                                      .bronze.PU +
                                    " | MS: " +
                                    this.state.athlete_data.medals.ibu_details
                                      .bronze.MS +
                                    " | IN: " +
                                    this.state.athlete_data.medals.ibu_details
                                      .bronze.IN
                                  }
                                  position="top center"
                                />
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>
                                {" "}
                                <Icon name="trophy" /> World Championships
                              </Table.Cell>
                              <Table.Cell className="big_number centered_cell">
                                <Popup
                                  trigger={
                                    <span className="overpointer">
                                      {this.state.athlete_data.medals.wc_gold}
                                    </span>
                                  }
                                  content={
                                    "SP: " +
                                    this.state.athlete_data.medals.wc_details
                                      .gold.SP +
                                    " | PU: " +
                                    this.state.athlete_data.medals.wc_details
                                      .gold.PU +
                                    " | MS: " +
                                    this.state.athlete_data.medals.wc_details
                                      .gold.MS +
                                    " | IN: " +
                                    this.state.athlete_data.medals.wc_details
                                      .gold.IN
                                  }
                                  position="top center"
                                />
                              </Table.Cell>
                              <Table.Cell className="big_number centered_cell">
                                <Popup
                                  trigger={
                                    <span className="overpointer">
                                      {this.state.athlete_data.medals.wc_silver}
                                    </span>
                                  }
                                  content={
                                    "SP: " +
                                    this.state.athlete_data.medals.wc_details
                                      .silver.SP +
                                    " | PU: " +
                                    this.state.athlete_data.medals.wc_details
                                      .silver.PU +
                                    " | MS: " +
                                    this.state.athlete_data.medals.wc_details
                                      .silver.MS +
                                    " | IN: " +
                                    this.state.athlete_data.medals.wc_details
                                      .silver.IN
                                  }
                                  position="top center"
                                />
                              </Table.Cell>
                              <Table.Cell className="big_number centered_cell">
                                <Popup
                                  trigger={
                                    <span className="overpointer">
                                      {this.state.athlete_data.medals.wc_bronze}
                                    </span>
                                  }
                                  content={
                                    "SP: " +
                                    this.state.athlete_data.medals.wc_details
                                      .bronze.SP +
                                    " | PU: " +
                                    this.state.athlete_data.medals.wc_details
                                      .bronze.PU +
                                    " | MS: " +
                                    this.state.athlete_data.medals.wc_details
                                      .bronze.MS +
                                    " | IN: " +
                                    this.state.athlete_data.medals.wc_details
                                      .bronze.IN
                                  }
                                  position="top center"
                                />
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>
                                {" "}
                                <Icon name="trophy" /> Olympic Games
                              </Table.Cell>
                              <Table.Cell className="big_number centered_cell">
                                <Popup
                                  trigger={
                                    <span className="overpointer">
                                      {this.state.athlete_data.medals.og_gold}
                                    </span>
                                  }
                                  content={
                                    "SP: " +
                                    this.state.athlete_data.medals.og_details
                                      .gold.SP +
                                    " | PU: " +
                                    this.state.athlete_data.medals.og_details
                                      .gold.PU +
                                    " | MS: " +
                                    this.state.athlete_data.medals.og_details
                                      .gold.MS +
                                    " | IN: " +
                                    this.state.athlete_data.medals.og_details
                                      .gold.IN
                                  }
                                  position="top center"
                                />
                              </Table.Cell>
                              <Table.Cell className="big_number centered_cell">
                                <Popup
                                  trigger={
                                    <span className="overpointer">
                                      {this.state.athlete_data.medals.og_silver}
                                    </span>
                                  }
                                  content={
                                    "SP: " +
                                    this.state.athlete_data.medals.og_details
                                      .silver.SP +
                                    " | PU: " +
                                    this.state.athlete_data.medals.og_details
                                      .silver.PU +
                                    " | MS: " +
                                    this.state.athlete_data.medals.og_details
                                      .silver.MS +
                                    " | IN: " +
                                    this.state.athlete_data.medals.og_details
                                      .silver.IN
                                  }
                                  position="top center"
                                />
                              </Table.Cell>
                              <Table.Cell className="big_number centered_cell">
                                <Popup
                                  trigger={
                                    <span className="overpointer">
                                      {this.state.athlete_data.medals.og_bronze}
                                    </span>
                                  }
                                  content={
                                    "SP: " +
                                    this.state.athlete_data.medals.og_details
                                      .bronze.SP +
                                    " | PU: " +
                                    this.state.athlete_data.medals.og_details
                                      .bronze.PU +
                                    " | MS: " +
                                    this.state.athlete_data.medals.og_details
                                      .bronze.MS +
                                    " | IN: " +
                                    this.state.athlete_data.medals.og_details
                                      .bronze.IN
                                  }
                                  position="top center"
                                />
                              </Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      )}
                    </>
                  )}
                </div>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid stackable>
          <Grid.Row columns={3}>
            <Grid.Column>
              <Segment>
                {/*<div className="ribbon ribbon-top-left">
                  <span>new</span>
                                </div>*/}
                <Header as="h2" textAlign="center">
                  <span className="float-left">
                    <Icon name="target" />
                  </span>
                  Clean sheets
                </Header>
                <CleanSheetsAthleteContainer
                  athlete_id={this.state.selected_athlete}
                  current_name={this.state.current_name}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment>
                <Header as="h2" textAlign="center">
                  <span className="float-left">
                    <Icon name="chart bar" />
                  </span>
                  Average points per race
                </Header>
                <div className="history-container">
                  {this.state.loading ? (
                    <div className="LoaderContainer">
                      <Loader active content="Loading"></Loader>
                    </div>
                  ) : (
                    <PointsPerRaceAthleteContainer
                      athlete_id={this.state.selected_athlete}
                      current_name={this.state.current_name}
                    />
                  )}
                </div>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment>
                <Header as="h2" textAlign="center">
                  <span className="float-left">
                    <Icon name="trophy" />
                  </span>
                  Results distribution
                </Header>
                <div className="history-container">
                  {this.state.loading ? (
                    <div className="LoaderContainer">
                      <Loader active content="Loading"></Loader>
                    </div>
                  ) : (
                    <ResultsDistribAthleteContainer
                      athlete_id={this.state.selected_athlete}
                      current_name={this.state.current_name}
                    />
                  )}
                </div>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Segment>
                <Header as="h2" textAlign="center">
                  <span className="float-left">
                    <Icon name="chart bar" />
                  </span>
                  Skiing and shooting
                </Header>
                <SeasonsSkiingShootingAthleteContainer
                  athlete_id={this.state.selected_athlete}
                  current_name={this.state.current_name}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={1}>
            <Grid.Column>
              <Segment>
                <Header as="h2" textAlign="center">
                  <span className="float-left">
                    <Icon name="trophy" />
                  </span>
                  Rankings
                </Header>
                <SeasonsRankingsAthleteContainer
                  athlete_id={this.state.selected_athlete}
                  current_name={this.state.current_name}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={1}>
            <Grid.Column>
              <Segment>
                <Header as="h2" textAlign="center">
                  <span className="float-left">
                    <Icon name="trophy" />
                  </span>
                  Races Results
                </Header>
                <AllResultsAthleteContainer
                  athlete_id={this.state.selected_athlete}
                  current_name={this.state.current_name}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Segment>
                <Header as="h2" textAlign="center">
                  <span className="float-left">
                    <Icon name="target" />
                  </span>
                  Shooting Speed vs Accuracy
                </Header>
                <SpeedVsAccuracyAthleteContainer
                  athlete_id={this.state.selected_athlete}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Segment vertical textAlign="center">
          <h4>
            Athlete pictures:{" "}
            <a href="https://www.biathlonworld.com/">
              https://www.biathlonworld.com/
            </a>
          </h4>
        </Segment>

        <Segment vertical textAlign="center">
          <h4>
            <a href="https://twitter.com/biathstats">
              <span className="twitter_content">
                <Icon name="twitter" />
              </span>
            </a>
          </h4>
        </Segment>
      </div>
    );
  }
}

export const AthletePage = withRouter(NCAthlete);
