import React from "react";
import ApiService from "../utils/apiService";
import { ShootingTimeRanking } from "../components/ShootingTimeRanking";
import axios from "axios";

const CancelToken = axios.CancelToken;

export class ShootingTimeRankingContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      data: [],
    };
  }

  filter_datas(data, filters, filter_countries) {
    let newdata = data;
    if (filters.length > 0 || filter_countries.length > 0) {
      newdata = data.filter(
        (data) =>
          filters.includes(data.id) ||
          filter_countries.includes(data.nationality)
      );
    }
    return newdata;
  }
  add_ranking(data) {
    for (let i = 0; i < data.length; i++) {
      data[i].ranking = i + 1;
    }
    return data;
  }

  fetchDatas = async (gender, season, source) => {
    this.setState({ loading: true, error: false });
    try {
      const { data } = await ApiService.getShootingAverage(
        this.props.season,
        this.props.gender,
        source
      );
      if (gender === this.props.gender && season === this.props.season) {
        this.setState({ data: data });
      }
    } catch (error) {
      if (
        gender === this.props.gender &&
        season === this.props.season &&
        error.message !== "gender change"
      ) {
        this.setState({ error: true });
      }
    }
    if (gender === this.props.gender && season === this.props.season) {
      this.setState({ loading: false });
    }
  };

  async componentDidMount() {
    const newSource = CancelToken.source();
    this.setState({ source: newSource });
    this.fetchDatas(this.props.gender, this.props.season, newSource);
  }
  async componentDidUpdate(prevProps) {
    if (
      this.props.gender !== prevProps.gender ||
      this.props.season !== prevProps.season
    ) {
      if (this.state.loading) {
        ApiService.cancelFetch(this.state.source);
      }
      const newSource = CancelToken.source();
      this.setState({ data: [], source: newSource });
      this.fetchDatas(this.props.gender, this.props.season, newSource);
    }
  }

  render() {
    return (
      <ShootingTimeRanking
        data={this.filter_datas(
          this.add_ranking(this.state.data),
          this.props.filtered,
          this.props.filtered_countries
        )}
        loading={this.state.loading}
        error={this.state.error}
        showAthleteCard={this.props.showAthleteCard}
      />
    );
  }
}
