import React from "react";
import ApiService from "../utils/apiService";
import { Filters } from "../components/Filters";
import { COUNTRY_CODES_CONVERSION } from "../utils/constants";

export class FilterCountriesContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      data: [],
    };
  }

  buildCountriesDropdown(data) {
    return data.map((countrycode) => ({
      key: countrycode.nationality,
      text: COUNTRY_CODES_CONVERSION[countrycode.nationality]["name"],
      value: countrycode.nationality,
    }));
  }

  fetchDatas = async () => {
    this.setState({ loading: true });
    try {
      const { data } = await ApiService.getCountriesList(
        this.props.season,
        this.props.gender
      );
      this.setState({ data: this.buildCountriesDropdown(data) });
    } catch (error) {
      this.setState({ error: true });
      console.log(error);
    }
    this.setState({ loading: false });
  };

  async componentDidMount() {
    this.fetchDatas();
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.gender !== prevProps.gender ||
      this.props.season !== prevProps.season
    ) {
      this.fetchDatas();
    }
  }

  render() {
    return (
      <Filters
        data={this.state.data}
        loading={this.state.loading}
        error={this.state.error}
        handleFilter={this.props.handleFilteredCountries}
        filtersValue={this.props.filtersValue}
      />
    );
  }
}
