import React from "react";
import { Checkbox } from "semantic-ui-react";
import ApiService from "../utils/apiService";
import { Ranking } from "../components/Ranking";
import axios from "axios";

const CancelToken = axios.CancelToken;

export class RankingContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      data: [],
      scratchStatus: false,
      scratchCode: 0,
      done_races: "--",
      total_races: "--",
      hide_dropped: true,
    };
  }

  filter_datas(data, filters, filter_countries) {
    let newdata = data;
    if (filters.length > 0 || filter_countries.length > 0) {
      newdata = data.filter(
        (data) =>
          filters.includes(data.id) ||
          filter_countries.includes(data.nationality)
      );
    }
    return newdata;
  }
  add_ranking(data) {
    for (let i = 0; i < data.length; i++) {
      if (i === 0) {
        data[i].ranking = 1;
      } else {
        if (
          data[i].total_points === data[i - 1].total_points &&
          data[i].total_first === data[i - 1].total_first &&
          data[i].total_second === data[i - 1].total_second &&
          data[i].total_third === data[i - 1].total_third
        ) {
          data[i].ranking = data[i - 1].ranking;
        } else {
          data[i].ranking = i + 1;
        }
      }

      let isLeader = false;
      if (
        i === 0 ||
        (data[i].total_points === data[0].total_points &&
          data[i].total_first === data[0].total_first &&
          data[i].total_second === data[0].total_second &&
          data[i].total_third === data[0].total_third)
      ) {
        isLeader = true;
      }
      data[i].isLeader = isLeader;
    }
    return data;
  }

  fetchDatas = async (scratchCode, gender, season, source) => {
    this.setState({ loading: true, error: false });
    try {
      const { data } = await ApiService.getRanking(
        this.props.season,
        this.props.gender,
        this.props.requestUrl,
        this.props.discipline,
        scratchCode,
        source
      );
      if (gender === this.props.gender && season === this.props.season) {
        this.setState({ data: data });
        if (data.length > 0) {
          if (data[0].done_races) {
            this.setState({
              done_races: data[0].done_races,
              total_races: data[0].total_races,
              hide_dropped: data[0].hide_dropped,
            });
          }
          if (
            this.state.scratchCode === 0 &&
            data[0].done_races &&
            data[0].done_races === data[0].total_races
          ) {
            this.setState({ scratchStatus: true });
          }
        }
      }
    } catch (error) {
      if (
        gender === this.props.gender &&
        season === this.props.season &&
        error.message !== "gender change"
      ) {
        this.setState({ error: true });
      }
    }
    if (gender === this.props.gender && season === this.props.season) {
      this.setState({ loading: false });
    }
  };

  async componentDidMount() {
    const newSource = CancelToken.source();
    this.setState({ source: newSource });
    this.fetchDatas(0, this.props.gender, this.props.season, newSource);
  }
  async componentDidUpdate(prevProps) {
    if (
      this.props.gender !== prevProps.gender ||
      this.props.season !== prevProps.season
    ) {
      if (this.state.loading) {
        ApiService.cancelFetch(this.state.source);
      }
      const newSource = CancelToken.source();
      this.setState({ source: newSource, hide_dropped: true });
      this.fetchDatas(
        this.state.scratchCode,
        this.props.gender,
        this.props.season,
        newSource
      );
      this.setState({
        data: [],
        done_races: "--",
        total_races: "--",
      });
    }
  }

  handleScratchChange = (value) => {
    this.setState({ scratchStatus: value });
    const newSource = CancelToken.source();
    this.setState({ source: newSource });
    if (value) {
      this.setState({ scratchCode: 2 });
      this.fetchDatas(2, this.props.gender, this.props.season, newSource);
    } else {
      this.setState({ scratchCode: 1 });
      this.fetchDatas(1, this.props.gender, this.props.season, newSource);
    }
  };

  render() {
    return (
      <>
        {this.props.discipline && this.state.hide_dropped === false && (
          <div className="AbsoluteSlider">
            <span>
              {" "}
              w/dr<span className="hide_wdr">opped results:</span>
            </span>
            <Checkbox
              slider
              checked={this.state.scratchStatus}
              onChange={(e, data) => this.handleScratchChange(data.checked)}
            />
          </div>
        )}

        <span className="NbRacesInfos">
          {this.state.done_races} / {this.state.total_races}
        </span>

        <Ranking
          data={this.filter_datas(
            this.add_ranking(this.state.data),
            this.props.filtered,
            this.props.filtered_countries
          )}
          loading={this.state.loading}
          error={this.state.error}
          shirt={this.props.discipline ? "red" : "gold"}
          showAthleteCard={this.props.showAthleteCard}
        />
      </>
    );
  }
}
