import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Loader, Message } from "semantic-ui-react";

export class SeasonsSkiingShootingAthlete extends React.PureComponent {
  get options() {
    return {
      chart: {
        height: 410,
        type: "spline",
      },
      title: {
        text: null,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: this.props.data[0].categories,
      },
      yAxis: {
        title: {
          text: "Athlete Ranking",
        },
        labels: {
          formatter: function() {
            if (this.value !== 0) {
              return this.value;
            }
          },
        },
        min: 1,
        tickInterval: 5,
        reversed: true,
        gridLineWidth: 0,
        plotLines: [
          {
            value: 1,
            color: "#e8b318",
            dashStyle: "solid",
            width: 1,
            label: {
              text: "1",
              x: -20,
              y: 3,
              style: {
                fontSize: "10px",
                color: "#e8b318",
                fontWeight: "bold",
              },
            },
            zIndex: 1001,
          },
        ],
      },
      legend: {
        enabled: true,
      },
      plotOptions: {
        series: {
          lineWidth: 3,
          dataLabels: {
            enabled: true,
          },
        },
      },
      tooltip: {
        formatter: function() {
          return (
            "<b>" +
            this.series.name +
            "</b><br/><b>" +
            this.point.year +
            "<br/><b>ranking: " +
            this.y +
            this.point.plus
          );
        },
      },
      series: this.props.data[0].series,
    };
  }

  render() {
    if (this.props.error) {
      return (
        <Message
          negative
          className="text_center"
          content="Sorry, an error occured"
        ></Message>
      );
    }
    if (this.props.loading || this.props.data.length === 0) {
      return (
        <div className="LoaderContainer">
          <Loader active content="Loading"></Loader>
        </div>
      );
    }
    return (
      <>
        {this.props.empty_datas ? (
          <Message
            className="text_center"
            content="No data to display"
          ></Message>
        ) : (
          <div className="highcharts_content">
            <HighchartsReact highcharts={Highcharts} options={this.options} />
          </div>
        )}
      </>
    );
  }
}
