import React from "react";
import ApiService from "../utils/apiService";
import { SpecialEvent } from "../components/SpecialEvent";
import axios from "axios";

const CancelToken = axios.CancelToken;

export class SpecialEventContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      data: [],
    };
  }

  filter_datas(data, filters, filter_countries) {
    let newdata = JSON.parse(JSON.stringify(data));
    //let newdata = [...data];
    if (filters.length > 0 || filter_countries.length > 0) {
      for (let i = 0; i < newdata.length; i++) {
        for (let j = 0; j < newdata[i].result.length; j++) {
          if (
            filters.includes(newdata[i].result[j].id) === false &&
            filter_countries.includes(newdata[i].result[j].nationality) ===
              false
          ) {
            newdata[i].result[j] = null;
          }
        }
      }
    }
    return newdata;
  }
  add_ranking(data) {
    for (let i = 0; i < data.length; i++) {
      data[i].ranking = i + 1;
    }
    return data;
  }

  fetchDatas = async (gender, season, source) => {
    this.setState({ loading: true, error: false });
    try {
      const { data } = await ApiService.getSpecialEvent(
        this.props.season,
        this.props.gender,
        source
      );
      if (gender === this.props.gender && season === this.props.season) {
        this.setState({ data: data });
        if (data.length > 0) {
          this.props.setSpecialEventType(data[0].category);
        } else {
          this.props.setSpecialEventType("Special Event");
        }
      }
    } catch (error) {
      if (
        gender === this.props.gender &&
        season === this.props.season &&
        error.message !== "gender change"
      ) {
        this.setState({ error: true });
      }
    }
    if (gender === this.props.gender && season === this.props.season) {
      this.setState({ loading: false });
    }
  };

  async componentDidMount() {
    const newSource = CancelToken.source();
    this.setState({ source: newSource });
    this.fetchDatas(this.props.gender, this.props.season, newSource);
  }
  async componentDidUpdate(prevProps) {
    if (
      this.props.gender !== prevProps.gender ||
      this.props.season !== prevProps.season
    ) {
      if (this.state.loading) {
        ApiService.cancelFetch(this.state.source);
      }
      const newSource = CancelToken.source();
      this.setState({ data: [], source: newSource });
      this.fetchDatas(this.props.gender, this.props.season, newSource);
    }
  }

  render() {
    return (
      <SpecialEvent
        data={this.filter_datas(
          this.add_ranking(this.state.data),
          this.props.filtered,
          this.props.filtered_countries
        )}
        loading={this.state.loading}
        error={this.state.error}
        showAthleteCard={this.props.showAthleteCard}
      />
    );
  }
}
