import React from "react";
import ApiService from "../utils/apiService";
import { SingleRace } from "../components/SingleRace";
import { Message, Loader } from "semantic-ui-react";
import axios from "axios";

const CancelToken = axios.CancelToken;

export class SingleRaceContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      data: [],
      loading_race: true,
      error_race: false,
      data_race: { results: [] },
      data_race_not_filtered: { results: [] },
      current_race: null,
      column: "ranking",
      direction: "descending",
      race_id: null,
    };
  }
  //arrow to bind this
  handleSort = (clickedColumn) => () => {
    const { column, data_race, direction } = this.state;

    if (column !== clickedColumn) {
      data_race.results = data_race.results.sort(
        (a, b) => a[clickedColumn] - b[clickedColumn]
      );
      this.setState({
        column: clickedColumn,
        data_race: data_race,
        direction: "ascending",
      });

      return;
    }
    data_race.results = data_race.results.reverse();
    this.setState({
      data_race: data_race,
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };

  fetchDatas = async (source, initial) => {
    this.setState({ loading: true, error: false });
    try {
      const { data } = await ApiService.getRaceList(
        this.props.season,
        this.props.gender,
        source
      );
      this.setState({ data: data });
      if (data.length > 0) {
        this.setState({ race_id: data[0].value });
        this.fetchLastRaceDatas(source);
      }
    } catch (error) {
      if (error.message !== "gender change") {
        this.setState({ error: true });
      }
    }
    this.setState({ loading: false });
  };

  fetchRaceDatas = async (race_id, source) => {
    this.setState({ loading_race: true, error_race: false });
    try {
      const { data } = await ApiService.getRace(race_id, source);
      this.setState({
        column: "ranking",
        direction: "descending",
        data_race_not_filtered: JSON.parse(JSON.stringify(data)),
        data_race: this.add_ranking(data),
      });
    } catch (error) {
      if (error.message !== "gender change") {
        this.setState({ error_race: true });
      }
    }
    this.setState({ loading_race: false });
  };

  fetchLastRaceDatas = async (source) => {
    this.setState({ loading_race: true, error_race: false });
    try {
      const { data } = await ApiService.getLastRace(
        this.props.season,
        this.props.gender,
        source
      );
      this.setState({
        column: "ranking",
        direction: "descending",
        data_race_not_filtered: JSON.parse(JSON.stringify(data)),
        data_race: this.add_ranking(data),
      });
    } catch (error) {
      if (error.message !== "gender change") {
        this.setState({ error_race: true });
      }
    }
    this.setState({ loading_race: false });
  };

  async componentDidMount() {
    const newSource = CancelToken.source();
    this.setState({ source: newSource });
    this.fetchDatas(newSource);
  }
  async componentDidUpdate(prevProps) {
    if (
      this.props.gender !== prevProps.gender ||
      this.props.season !== prevProps.season
    ) {
      ApiService.cancelFetch(this.state.source);
      const newSource = CancelToken.source();
      this.setState({ source: newSource, loading: true, loading_race: true });
      this.fetchDatas(newSource);
    }
  }

  add_ranking(data) {
    for (let i = 0; i < data.results.length; i++) {
      data.results[i].ranking = i + 1;
    }
    return data;
  }

  filter_datas(data, filters, filter_countries) {
    let newdata = { ...data };
    if (filters.length > 0 || filter_countries.length > 0) {
      newdata.results = data.results.filter(
        (singledata) =>
          filters.includes(singledata.athlete.id) ||
          filter_countries.includes(singledata.athlete.nationality)
      );
    }
    return newdata;
  }

  handleRaceChange = (event, data) => {
    this.setState({ race_id: data.value });
    ApiService.cancelFetch(this.state.source);
    const newSource = CancelToken.source();
    this.setState({ source: newSource, loading_race: true });
    this.fetchRaceDatas(data.value, newSource);
  };

  render() {
    if (this.state.error) {
      return (
        <Message
          negative
          className="text_center"
          content="Sorry, an error occured"
        ></Message>
      );
    }
    if (this.state.loading) {
      return (
        <div className="LoaderContainer">
          <Loader active content="Loading"></Loader>
        </div>
      );
    }
    if (this.state.data.length === 0) {
      return (
        <Message
          className="text_center"
          content="No races to display"
        ></Message>
      );
    }
    return (
      <SingleRace
        race_id={this.state.race_id}
        data={this.state.data}
        loading={this.state.loading}
        error={this.state.error}
        data_race_not_filtered={this.state.data_race_not_filtered}
        data_race={this.filter_datas(
          this.state.data_race,
          this.props.filtered,
          this.props.filtered_countries
        )}
        loading_race={this.state.loading_race}
        error_race={this.state.error_race}
        handleRaceChange={this.handleRaceChange}
        filtersValue={this.props.filtersValue}
        showAthleteCard={this.props.showAthleteCard}
        handleSort={this.handleSort}
        column={this.state.column}
        direction={this.state.direction}
        changeSingleRaceView={this.props.changeSingleRaceView}
        single_race_fullView={this.props.single_race_fullView}
      />
    );
  }
}
