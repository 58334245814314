import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { HomePage } from "./containers/HomePage";
import { AthletePage } from "./containers/AthletePage";
import { BirthdayPage } from "./containers/BirthdayPage";
import { PredictionPage } from "./containers/PredictionPage";
import "./styles/App.css";
import "./styles/weather-icons.min.css";
import "./styles/weather-icons-wind.min.css";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/athlete/:id" component={AthletePage} />
        <Route path="/birthday" component={BirthdayPage} />
        <Route path="/predictions" component={PredictionPage} />
        <Route exact path="/" component={HomePage} />
      </Switch>
    </Router>
  );
}

export default App;
