import React from "react";
import ApiService from "../utils/apiService";
import { PointsPerRace } from "../components/PointsPerRace";
import axios from "axios";

const CancelToken = axios.CancelToken;

export class PointsPerRaceContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      data: [],
      threshold: 0,
      max: 60,
      empty_datas: true,
      filtered_dropped: "",
      filtered_disciplines: "all",
    };
  }

  isEmptyData(datas) {
    let is_empty = true;
    if (datas.length > 0) {
      is_empty = false;
    }
    return is_empty;
  }

  filter_datas(data, filters, filter_countries) {
    let newdata = data;
    if (filters.length > 0 || filter_countries.length > 0) {
      newdata = data.filter(
        (data) =>
          filters.includes(data.id) ||
          filter_countries.includes(data.nationality)
      );
    } else {
      newdata = data.slice(0, 5);
    }
    return newdata;
  }

  fetchDatas = async (gender, season, source) => {
    this.setState({ loading: true });
    try {
      const { data } = await ApiService.getPointsPerRace(
        this.props.season,
        this.props.gender,
        source
      );
      if (gender === this.props.gender && season === this.props.season) {
        this.setState({
          data: data.results,
          threshold: data.threshold,
          max: data.max,
        });
        this.setState({ empty_datas: this.isEmptyData(data.results) });
      }
    } catch (error) {
      if (
        gender === this.props.gender &&
        season === this.props.season &&
        error.message !== "gender change"
      ) {
        this.setState({ error: true });
      }
    }
    if (gender === this.props.gender && season === this.props.season) {
      this.setState({ loading: false });
    }
  };

  handleFilteredDropped = (event, data) => {
    this.setState({ filtered_dropped: data.value });
  };

  handleFilteredDiscipline = (event, data) => {
    this.setState({ filtered_disciplines: data.value });
  };

  async componentDidMount() {
    const newSource = CancelToken.source();
    this.setState({ source: newSource });
    this.fetchDatas(this.props.gender, this.props.season, newSource);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.props.season !== prevProps.season ||
      this.props.gender !== prevProps.gender
    ) {
      ApiService.cancelFetch(this.state.source);

      const newSource = CancelToken.source();
      this.setState({ data: [], source: newSource });
      this.fetchDatas(this.props.gender, this.props.season, newSource);
    } else {
      if (
        this.props.filtered !== prevProps.filtered ||
        this.props.filtered_countries !== prevProps.filtered_countries
      ) {
        ApiService.cancelFetch(this.state.source);

        const newSource = CancelToken.source();
        this.setState({ data: [], source: newSource });
        this.fetchDatas(this.props.gender, this.props.season, newSource);
      }
    }
  }

  render() {
    return (
      <>
        <PointsPerRace
          data={this.filter_datas(
            this.state.data,
            this.props.filtered,
            this.props.filtered_countries
          )}
          loading={this.state.loading}
          error={this.state.error}
          empty_datas={this.isEmptyData(
            this.filter_datas(
              this.state.data,
              this.props.filtered,
              this.props.filtered_countries
            )
          )}
          threshold={this.state.threshold}
          max={this.state.max}
        />
      </>
    );
  }
}
