import React from "react";
import { Dropdown } from "semantic-ui-react";

export class Filters extends React.PureComponent {
  render() {
    return (
      <Dropdown
        placeholder="Select items to filter datas"
        fluid
        multiple
        search
        selection
        options={this.props.data}
        onChange={this.props.handleFilter}
        loading={this.props.loading}
        value={this.props.filtersValue}
      />
    );
  }
}
