import React from "react";
import ApiService from "../utils/apiService";
import { CleanSheet } from "../components/CleanSheet";
import { Segment, Dropdown } from "semantic-ui-react";
import axios from "axios";

const options = [
  { key: "All", text: "All Races", value: "all" },
  { key: "2 shots races", text: "2 shots races", value: 2 },
  { key: "4 shot races", text: "4 shots races", value: 4 },
];

const CancelToken = axios.CancelToken;

export class CleanSheetContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      data: [],
      selected_discipline: "all",
    };
  }

  handleFilteredDiscipline = (event, data) => {
    this.setState({ selected_discipline: data.value });
    this.fetchDatas(
      this.props.gender,
      this.props.season,
      data.value,
      this.state.source
    );
  };

  filter_datas(data, filters, filter_countries) {
    let newdata = data;
    if (filters.length > 0 || filter_countries.length > 0) {
      newdata = data.filter(
        (data) =>
          filters.includes(data.id) ||
          filter_countries.includes(data.nationality)
      );
    }
    return newdata;
  }

  add_ranking(data) {
    for (let i = 0; i < data.length; i++) {
      if (i === 0) {
        data[i].ranking = 1;
      } else {
        if (
          data[i].clean_races === data[i - 1].clean_races &&
          data[i].clean_percent === data[i - 1].clean_percent &&
          data[i].clean_fivefive === data[i - 1].clean_fivefive
        ) {
          data[i].ranking = data[i - 1].ranking;
        } else {
          data[i].ranking = i + 1;
        }
      }

      let isLeader = false;
      if (
        i === 0 ||
        (data[i].clean_races === data[0].clean_races &&
          data[i].clean_percent === data[0].clean_percent &&
          data[i].clean_fivefive === data[0].clean_fivefive)
      ) {
        isLeader = true;
      }
      data[i].isLeader = isLeader;
    }
    return data;
  }

  fetchDatas = async (gender, season, discipline, source) => {
    this.setState({ loading: true, error: false });
    try {
      const { data } = await ApiService.getCleanSheet(
        this.props.season,
        this.props.gender,
        discipline,
        source
      );
      if (gender === this.props.gender && season === this.props.season) {
        this.setState({ data: data });
      }
    } catch (error) {
      if (
        gender === this.props.gender &&
        season === this.props.season &&
        error.message !== "gender change"
      ) {
        this.setState({ error: true });
      }
    }
    if (gender === this.props.gender && season === this.props.season) {
      this.setState({ loading: false });
    }
  };

  async componentDidMount() {
    const newSource = CancelToken.source();
    this.setState({ source: newSource });
    this.fetchDatas(
      this.props.gender,
      this.props.season,
      this.state.selected_discipline,
      newSource
    );
  }
  async componentDidUpdate(prevProps) {
    if (
      this.props.gender !== prevProps.gender ||
      this.props.season !== prevProps.season
    ) {
      if (this.state.loading) {
        ApiService.cancelFetch(this.state.source);
      }
      const newSource = CancelToken.source();
      this.setState({ source: newSource, hide_dropped: true });
      this.fetchDatas(
        this.props.gender,
        this.props.season,
        this.state.selected_discipline,
        newSource
      );
      this.setState({
        data: [],
      });
    }
  }

  handleScratchChange = (value) => {
    this.setState({ scratchStatus: value });
    const newSource = CancelToken.source();
    this.setState({ source: newSource });
    if (value) {
      this.setState({ scratchCode: 2 });
      this.fetchDatas(2, this.props.gender, this.props.season, newSource);
    } else {
      this.setState({ scratchCode: 1 });
      this.fetchDatas(1, this.props.gender, this.props.season, newSource);
    }
  };

  render() {
    return (
      <>
        <Segment className="noborder">
          <Dropdown
            placeholder="Races type"
            fluid
            selection
            value={this.state.selected_discipline}
            options={options}
            disabled={this.props.loading}
            onChange={this.handleFilteredDiscipline}
          />
        </Segment>
        <CleanSheet
          data={this.filter_datas(
            this.add_ranking(this.state.data),
            this.props.filtered,
            this.props.filtered_countries
          )}
          loading={this.state.loading}
          error={this.state.error}
          showAthleteCard={this.props.showAthleteCard}
        />
      </>
    );
  }
}
