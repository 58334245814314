import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Loader, Message, Grid, Label } from "semantic-ui-react";

export class CleanSheetsAthlete extends React.PureComponent {
  get options() {
    return {
      chart: {
        height: 210,
        type: "column",
      },
      title: {
        text: null,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: this.props.data.categories,
      },
      yAxis: {
        title: {
          text: null,
        },
        tickInterval: 5,
        labels: {
          formatter: function() {
            if (this.value !== 0) {
              return this.value;
            }
          },
        },
        gridLineWidth: 0,
        allowDecimals: false,
        min: 0,
      },
      legend: {
        enabled: true,
      },

      plotOptions: {
        column: {
          stacking: "normal",
          pointPadding: 0.2,
          borderWidth: 0,
          /* Here is the setting to limit the maximum column width. */
          maxPointWidth: 26,
          dataLabels: {
            enabled: true,
          },
        },
      },
      tooltip: {
        formatter: function() {
          return (
            "<b>" +
            this.x +
            "</b><br/>" +
            this.series.name +
            ": " +
            this.y +
            "<br/>" +
            "Total: " +
            this.point.stackTotal
          );
        },
      },
      series: this.props.data.series,
    };
  }

  render() {
    if (this.props.error) {
      return (
        <Message
          negative
          className="text_center"
          content="Sorry, an error occured"
        ></Message>
      );
    }
    if (this.props.loading) {
      return (
        <div className="LoaderContainer">
          <Loader active content="Loading"></Loader>
        </div>
      );
    }
    return (
      <>
        {this.props.empty_datas ? (
          <Message
            className="text_center"
            content="No data to display"
          ></Message>
        ) : (
          <>
            <Grid>
              <Grid.Row columns={3}>
                <Grid.Column className="grid-center-icon">
                  <Label circular color="grey" size="massive">
                    {this.props.data.total}
                  </Label>
                  <br />
                  All races
                  <br />
                  5/5: <b>{this.props.data.total_55}</b>
                </Grid.Column>
                <Grid.Column className="grid-center-icon2">
                  <Label circular color="grey" size="massive">
                    {this.props.data.total_2}
                  </Label>
                  <br />2 shots races
                  <br />
                  5/5: <b>{this.props.data.total_2_55}</b>
                </Grid.Column>
                <Grid.Column className="grid-center-icon2">
                  <Label circular color="grey" size="massive">
                    {this.props.data.total_4}
                  </Label>
                  <br />4 shots races
                  <br />
                  5/5: <b>{this.props.data.total_4_55}</b>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <div className="highcharts_content">
              <HighchartsReact highcharts={Highcharts} options={this.options} />
            </div>
          </>
        )}
      </>
    );
  }
}
