import React from "react";
import ApiService from "../utils/apiService";
import { PointsPerRaceAthlete } from "../components/PointsPerRaceAthlete";

import axios from "axios";

const CancelToken = axios.CancelToken;

export class PointsPerRaceAthleteContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      data: [],
    };
  }

  isEmptyData(datas) {
    let is_empty = false;
    return is_empty;
  }

  highchartsData(data) {
    let categories = [];
    /*
    let total = data.total;
    let total_55 = data.total_55;
    let total_2 = data.total_2;
    let total_2_55 = data.total_2_55;
    let total_4 = data.total_4;
    let total_4_55 = data.total_4_55;
    */

    let array_total = [];
    let array_total_winner = [];
    if (data.seasons && data.seasons.length > 0) {
      for (let single_data of data.seasons) {
        categories.push(single_data.season);

        array_total.push(single_data.total);
        array_total_winner.push(single_data.total_winner);
      }
    }

    let highchartsData = {
      /*
      total: total,
      total_55: total_55,
      total_2: total_2,
      total_2_55: total_2_55,
      total_4: total_4,
      total_4_55: total_4_55,
      */
      categories: categories,
      series: [
        {
          type: "column",
          name: "Points per race",
          data: array_total,
        },
        {
          type: "spline",
          color: "#e8b318",
          name: "Winner average points per race (seasons with same scale)",
          data: array_total_winner,
        },
      ],
      categories_single: [
        "Average points per race (based on dropped results ranking)",
      ],
      series_single: [
        {
          type: "bar",
          name: data.name,
          data: [data.total],
        },
        {
          type: "bar",
          color: "#e8b318",
          name: "Winner average points per race (seasons with same scale)",
          data: [data.total_winner],
        },
      ],
    };

    return highchartsData;
  }

  fetchDatas = async (athlete_id, source) => {
    this.setState({ loading: true, error: false });
    try {
      const { data } = await ApiService.getPointsPerRaceAthlete(
        athlete_id,
        source
      );
      this.setState({ data: data });
    } catch (error) {
      if (error.message !== "gender change") {
        this.setState({ error: true });
      }
    }
    this.setState({ loading: false });
  };

  async componentDidMount() {
    const newSource = CancelToken.source();
    this.setState({ source: newSource });
    this.fetchDatas(this.props.athlete_id, newSource);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.props.athlete_id !== prevProps.athlete_id) {
      ApiService.cancelFetch(this.state.source);

      const newSource = CancelToken.source();
      this.setState({ data: [], source: newSource });
      this.fetchDatas(this.props.athlete_id, newSource);
    }
  }

  render() {
    return (
      <>
        <PointsPerRaceAthlete
          data={this.highchartsData(this.state.data)}
          loading={this.state.loading}
          error={this.state.error}
          empty_datas={this.isEmptyData(this.state.data)}
        />
      </>
    );
  }
}
