import React from "react";
import ApiService from "../utils/apiService";
import { CleanSheetsAthlete } from "../components/CleanSheetsAthlete";

import axios from "axios";

const CancelToken = axios.CancelToken;

export class CleanSheetsAthleteContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      data: [],
    };
  }

  isEmptyData(datas) {
    /*let is_empty = true;
    for (let data of datas) {
      if (data.seasons.length > 0) {
        is_empty = false;
      }
    }*/
    let is_empty = false;
    return is_empty;
  }

  filterData = (data) => {
    let new_data = [...data];
    if (this.state.selected_race && this.state.selected_race !== "all") {
      if (this.state.selected_race === "Sprint+Pursuit") {
        new_data = new_data.filter(
          (single_result) =>
            single_result.race.discipline.name === "Sprint" ||
            single_result.race.discipline.name === "Pursuit"
        );
      } else {
        new_data = new_data.filter(
          (single_result) =>
            single_result.race.discipline.name === this.state.selected_race
        );
      }
    }
    return new_data;
  };

  highchartsData(data) {
    let categories = [];
    let total = data.total;
    let total_55 = data.total_55;
    let total_2 = data.total_2;
    let total_2_55 = data.total_2_55;
    let total_4 = data.total_4;
    let total_4_55 = data.total_4_55;

    let array_total2 = [];
    let array_total4 = [];
    if (data.seasons && data.seasons.length > 0) {
      for (let single_data of data.seasons) {
        categories.push(single_data.season);

        array_total2.push(single_data.total_2);
        array_total4.push(single_data.total4);
      }
    }

    let highchartsData = {
      total: total,
      total_55: total_55,
      total_2: total_2,
      total_2_55: total_2_55,
      total_4: total_4,
      total_4_55: total_4_55,
      categories: categories,
      series: [
        {
          name: "2 shots races",
          data: array_total2,
          stack: "clean_sheets",
        },
        {
          name: "4 shots races",
          data: array_total4,
          stack: "clean_sheets",
        },
      ],
    };

    return highchartsData;
  }

  fetchDatas = async (athlete_id, source) => {
    this.setState({ loading: true, error: false });
    try {
      const { data } = await ApiService.getCleanSheetsAthlete(
        athlete_id,
        source
      );
      this.setState({ data: data });
    } catch (error) {
      if (error.message !== "gender change") {
        this.setState({ error: true });
      }
    }
    this.setState({ loading: false });
  };

  async componentDidMount() {
    const newSource = CancelToken.source();
    this.setState({ source: newSource });
    this.fetchDatas(this.props.athlete_id, newSource);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.props.athlete_id !== prevProps.athlete_id) {
      ApiService.cancelFetch(this.state.source);

      const newSource = CancelToken.source();
      this.setState({ data: [], source: newSource });
      this.fetchDatas(this.props.athlete_id, newSource);
    }
  }

  render() {
    return (
      <>
        <CleanSheetsAthlete
          data={this.highchartsData(this.state.data)}
          loading={this.state.loading}
          error={this.state.error}
          empty_datas={this.isEmptyData(this.state.data)}
        />
      </>
    );
  }
}
