import React from "react";
import ApiService from "../utils/apiService";
import { Dropdown, Segment } from "semantic-ui-react";
import { AthleteResultsGraphAthlete } from "../components/AthleteResultsGraphAthlete";

import axios from "axios";

const CancelToken = axios.CancelToken;

const options = [
  { key: "All", text: "All Disciplines", value: "all" },
  { key: "Sprint", text: "Sprint", value: "Sprint" },
  { key: "Pursuit", text: "Pursuit", value: "Pursuit" },
  { key: "SprintPursuit", text: "Sprint + Pursuit", value: "Sprint+Pursuit" },
  { key: "Mass-Start", text: "Mass-Start", value: "Mass-Start" },
  { key: "Individual", text: "Individual", value: "Individual" },
];

export class AllResultsAthleteContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      data: [],
      selected_race: "all",
    };
  }

  handleFilteredRaces = (event, data) => {
    this.setState({ selected_race: data.value });
    //this.fetchDatas(this.props.athlete_id, data.value, this.state.source);
  };

  isEmptyData(datas) {
    let is_empty = true;
    for (let data of datas) {
      if (data.data.length > 0) {
        is_empty = false;
      }
    }
    return is_empty;
  }

  filterData = (data) => {
    let new_data = [...data];
    if (this.state.selected_race && this.state.selected_race !== "all") {
      if (this.state.selected_race === "Sprint+Pursuit") {
        new_data = new_data.filter(
          (single_result) =>
            single_result.race.discipline.name === "Sprint" ||
            single_result.race.discipline.name === "Pursuit"
        );
      } else {
        new_data = new_data.filter(
          (single_result) =>
            single_result.race.discipline.name === this.state.selected_race
        );
      }
    }
    return new_data;
  };

  highchartsData(data) {
    let categories = [];
    let series_data = [];
    for (let single_data of data) {
      categories.push(single_data.race.race_date);
      if (single_data.position === 1000) {
        series_data.push(null);
      } else {
        series_data.push({
          y: single_data.position,
          place: single_data.race.event.short_description,
          discipline: single_data.race.discipline.name,
          race_date: single_data.race.race_date,
        });
      }
    }
    let highchartsData = [
      {
        categories: categories,
        series: [
          {
            name: this.props.current_name,
            data: series_data,
          },
        ],
      },
    ];
    return highchartsData;
  }

  fetchDatas = async (athlete_id, racetype, source) => {
    this.setState({ loading: true });
    try {
      const { data } = await ApiService.getAllResultsAthlete(
        athlete_id,
        racetype,
        source
      );
      this.setState({ data: data });
    } catch (error) {
      if (error.message !== "gender change") {
        this.setState({ error: true });
      }
    }
    this.setState({ loading: false });
  };

  async componentDidMount() {
    const newSource = CancelToken.source();
    this.setState({ source: newSource });
    this.fetchDatas(this.props.athlete_id, this.state.selected_race, newSource);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.props.athlete_id !== prevProps.athlete_id) {
      ApiService.cancelFetch(this.state.source);

      const newSource = CancelToken.source();
      this.setState({ data: [], source: newSource });
      this.fetchDatas(
        this.props.athlete_id,
        this.state.selected_race,
        newSource
      );
    }
  }

  render() {
    return (
      <>
        <Segment className="noborder marginplus">
          <Dropdown
            placeholder="All shootings"
            fluid
            selection
            value={this.state.selected_race}
            options={options}
            disabled={this.props.loading}
            onChange={this.handleFilteredRaces}
          />
        </Segment>
        <AthleteResultsGraphAthlete
          data={this.highchartsData(this.filterData(this.state.data))}
          loading={this.state.loading}
          error={this.state.error}
          empty_datas={false}
        />
      </>
    );
  }
}
