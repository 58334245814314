import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMap from "highcharts/modules/map";
import map from "@highcharts/map-collection/custom/world.geo.json";
import { Loader, Message } from "semantic-ui-react";
highchartsMap(Highcharts);

export class WorldMap extends React.PureComponent {
  get options() {
    return {
      chart: {
        map: map,
        //height: "600px",
      },
      title: {
        text: null,
      },
      credits: {
        enabled: false,
      },

      legend: {
        title: {
          text: "Average Points per athlete",
          style: {
            color:
              // theme
              (Highcharts.defaultOptions &&
                Highcharts.defaultOptions.legend &&
                Highcharts.defaultOptions.legend.title &&
                Highcharts.defaultOptions.legend.title.style &&
                Highcharts.defaultOptions.legend.title.style.color) ||
              "black",
          },
        },
      },

      mapNavigation: {
        enabled: true,
        buttonOptions: {
          verticalAlign: "bottom",
        },
      },

      plotOptions: {
        map: {
          dataLabels: {
            enabled: true,
            style: {
              fontWeight: "bold",
              //fontSize: '16px'
            },
          },
        },
      },

      colorAxis: {
        min: 0,
        max: 60,
      },

      series: [
        {
          data: this.props.data,
          joinBy: ["iso-a3", "code3"],
          name: "Average points per athlete",
          states: {
            hover: {
              color: "#a4edba",
            },
          },
        },
      ],
    };
  }

  render() {
    if (this.props.error) {
      return (
        <Message
          negative
          className="text_center"
          content="Sorry, an error occured"
        ></Message>
      );
    }
    if (this.props.loading || this.props.data.length === 0) {
      return (
        <div className="LoaderContainer">
          <Loader active content="Loading"></Loader>
        </div>
      );
    }
    return (
      <>
        {this.props.empty_datas ? (
          <Message
            className="text_center"
            content="No data to display"
          ></Message>
        ) : (
          <div className="highcharts_content">
            <HighchartsReact
              highcharts={Highcharts}
              options={this.options}
              constructorType={"mapChart"}
            />
          </div>
        )}
      </>
    );
  }
}
