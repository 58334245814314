import React from "react";
import {
  Grid,
  Segment,
  Header,
  Icon,
  Dropdown,
  Label,
  Button,
  Loader,
  Table,
  Message,
} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import ApiService from "../utils/apiService";
import { COUNTRY_CODES_CONVERSION } from "../utils/constants";

import axios from "axios";

const CancelToken = axios.CancelToken;

const month_options = [
  { key: 1, text: "01", value: 1 },
  { key: 2, text: "02", value: 2 },
  { key: 3, text: "03", value: 3 },
  { key: 4, text: "04", value: 4 },
  { key: 5, text: "05", value: 5 },
  { key: 6, text: "06", value: 6 },
  { key: 7, text: "07", value: 7 },
  { key: 8, text: "08", value: 8 },
  { key: 9, text: "09", value: 9 },
  { key: 10, text: "10", value: 10 },
  { key: 11, text: "11", value: 11 },
  { key: 12, text: "12", value: 12 },
];

const day_options = [
  { key: 1, text: "01", value: 1 },
  { key: 2, text: "02", value: 2 },
  { key: 3, text: "03", value: 3 },
  { key: 4, text: "04", value: 4 },
  { key: 5, text: "05", value: 5 },
  { key: 6, text: "06", value: 6 },
  { key: 7, text: "07", value: 7 },
  { key: 8, text: "08", value: 8 },
  { key: 9, text: "09", value: 9 },
  { key: 10, text: "10", value: 10 },
  { key: 11, text: "11", value: 11 },
  { key: 12, text: "12", value: 12 },
  { key: 13, text: "13", value: 13 },
  { key: 14, text: "14", value: 14 },
  { key: 15, text: "15", value: 15 },
  { key: 16, text: "16", value: 16 },
  { key: 17, text: "17", value: 17 },
  { key: 18, text: "18", value: 18 },
  { key: 19, text: "19", value: 19 },
  { key: 20, text: "20", value: 20 },
  { key: 21, text: "21", value: 21 },
  { key: 22, text: "22", value: 22 },
  { key: 23, text: "23", value: 23 },
  { key: 24, text: "24", value: 24 },
  { key: 25, text: "25", value: 25 },
  { key: 26, text: "26", value: 26 },
  { key: 27, text: "27", value: 27 },
  { key: 28, text: "28", value: 28 },
  { key: 29, text: "29", value: 29 },
  { key: 30, text: "30", value: 30 },
  { key: 31, text: "31", value: 31 },
];

const gender_options = [
  { key: 1, text: "all", value: "all" },
  { key: 2, text: "Women", value: "SW" },
  { key: 3, text: "Men", value: "SM" },
];

class NCBirthday extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      data: [],
      show_result: false,
      gender: "all",
      day: 1,
      month: 1,
      disabled_btn: false,
    };
  }

  handleGender = (event, data) => {
    this.setState({ gender: data.value });
  };
  handleDay = (event, data) => {
    this.setState({ day: data.value });
    this.checkDisabled(this.state.month, data.value);
  };
  handleMonth = (event, data) => {
    this.setState({ month: data.value });
    this.checkDisabled(data.value, this.state.day);
  };
  handleClick = () => {
    this.setState({ show_result: true });
    this.fetchDatas();
  };
  checkDisabled = (month, day) => {
    const month_not_31 = [2, 4, 6, 9, 11];
    const month_not_30 = [2];
    if (
      (day === 31 && month_not_31.includes(month)) ||
      (day === 30 && month_not_30.includes(month))
    ) {
      this.setState({ disabled_btn: true });
    } else {
      this.setState({ disabled_btn: false });
    }
  };

  fetchDatas = async () => {
    //all athletes
    this.setState({ loading: true, error: false });
    try {
      const { data } = await ApiService.getBirthdays(
        this.state.day,
        this.state.month,
        this.state.gender,
        this.state.source
      );
      this.setState({ data: data });
    } catch (error) {
      this.setState({ error: true });
    }
    this.setState({ loading: false });
  };

  async componentDidMount() {
    const newSource = CancelToken.source();
    this.setState({ source: newSource });
  }

  render() {
    return (
      <div id="container fullheight">
        <Header as="h1" textAlign="center" className="main_header">
          <img
            src="/static/img/logo_bs.png"
            alt="biathlon stats"
            className="logo_img"
            onClick={() => this.props.history.push("/")}
          />
        </Header>

        <Segment>
          <Header as="h2" textAlign="center">
            <span className="float-left">
              <Icon name="birthday" />
            </span>
            Biathletes birthdays!
          </Header>
          <div className="filters_area_men_single">
            <Grid stackable>
              <Grid.Row columns={3}>
                <Grid.Column />
                <Grid.Column>
                  <Grid divided>
                    <Grid.Row columns={3}>
                      <Grid.Column>
                        <Label>Gender</Label>

                        <Dropdown
                          placeholder="Gender"
                          fluid
                          selection
                          value={this.state.gender}
                          options={gender_options}
                          disabled={this.props.loading}
                          onChange={this.handleGender}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Label>Day</Label>

                        <Dropdown
                          placeholder="Day"
                          fluid
                          selection
                          value={this.state.day}
                          options={day_options}
                          disabled={this.props.loading}
                          onChange={this.handleDay}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Label>Month</Label>

                        <Dropdown
                          placeholder="Month"
                          fluid
                          selection
                          value={this.state.month}
                          options={month_options}
                          disabled={this.props.loading}
                          onChange={this.handleMonth}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
                <Grid.Column />
              </Grid.Row>
            </Grid>
          </div>
          <Segment vertical textAlign="center" className="noborder">
            {" "}
            <Button
              icon
              labelPosition="left"
              disabled={this.state.disabled_btn || this.state.loading}
              loading={this.state.loading}
              onClick={this.handleClick}
            >
              <Icon name="birthday" />
              SHOW BIATHLETES !
            </Button>
          </Segment>
        </Segment>

        <div className="custom_container">
          <Segment className="noborder">
            {this.state.loading ? (
              <div className="LoaderContainer">
                <Loader active content="Loading"></Loader>
              </div>
            ) : (
              <>
                {this.state.error ? (
                  <Message
                    negative
                    className="text_center"
                    content="Sorry, an error occured"
                  ></Message>
                ) : (
                  <>
                    {this.state.data.length === 0 && this.state.show_results ? (
                      <Message
                        className="text_center"
                        content="No biathlete to display"
                      ></Message>
                    ) : (
                      <>
                        {this.state.data.map((athlete) => (
                          <Segment key={athlete.id}>
                            <Grid>
                              <Grid.Row columns={2}>
                                <Grid.Column>
                                  {athlete.photo ? (
                                    <div className="athlete_picture_big-container">
                                      <img
                                        className="athlete_picture_big"
                                        alt={athlete.family_name}
                                        title={
                                          athlete.family_name +
                                          " " +
                                          athlete.given_name
                                        }
                                        src={athlete.photo}
                                      ></img>
                                    </div>
                                  ) : (
                                    <div className="athlete_picture_big-container athlete_picture_big-container--empty">
                                      <span className="athlete_picture_big"></span>
                                    </div>
                                  )}
                                  <span className="width100centered increaseIcon">
                                    <span className="trophy trophy_gold">
                                      {athlete.medals.gold}
                                      <Icon name="certificate" />
                                    </span>
                                    <span className="trophy trophy_silver">
                                      {athlete.medals.silver}
                                      <Icon name="certificate" />
                                    </span>
                                    <span className="trophy trophy_bronze">
                                      {athlete.medals.bronze}
                                      <Icon name="certificate" />
                                    </span>
                                  </span>
                                </Grid.Column>
                                <Grid.Column>
                                  <Header
                                    as="h2"
                                    textAlign="left"
                                    className="athlete_card_name"
                                  >
                                    {athlete.family_name}
                                  </Header>
                                  <Header as="h3" textAlign="left">
                                    {athlete.given_name}
                                  </Header>
                                  <Table className="athletecard_id_table">
                                    <Table.Body>
                                      <Table.Row>
                                        <Table.Cell>
                                          <img
                                            src={`static/img/${
                                              COUNTRY_CODES_CONVERSION[
                                                athlete.nationality
                                              ]["code"]
                                            }.png`}
                                            alt={
                                              COUNTRY_CODES_CONVERSION[
                                                athlete.nationality
                                              ]["name"]
                                            }
                                          />
                                        </Table.Cell>
                                        <Table.Cell>
                                          <Header as="h4" textAlign="left">
                                            {
                                              COUNTRY_CODES_CONVERSION[
                                                athlete.nationality
                                              ]["name"]
                                            }
                                          </Header>
                                        </Table.Cell>
                                      </Table.Row>
                                    </Table.Body>
                                  </Table>
                                  <div className="athlete_card_age">
                                    {athlete.gender === "SW" ? (
                                      <span className="woman_birth">
                                        <Icon name="woman" />
                                      </span>
                                    ) : (
                                      <span className="man_birth">
                                        <Icon name="man" />
                                      </span>
                                    )}
                                    {athlete.birth_date}
                                    <br />
                                    seasons: {athlete.seasons[0]} to{" "}
                                    {
                                      athlete.seasons[
                                        athlete.seasons.length - 1
                                      ]
                                    }
                                  </div>
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                          </Segment>
                        ))}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </Segment>
        </div>

        <Segment vertical textAlign="center">
          <h4>
            Athlete pictures:{" "}
            <a href="https://www.biathlonworld.com/">
              https://www.biathlonworld.com/
            </a>
          </h4>
        </Segment>

        <Segment vertical textAlign="center">
          <h4>
            <a href="https://twitter.com/biathstats">
              <span className="twitter_content">
                <Icon name="twitter" />
              </span>
            </a>
          </h4>
        </Segment>
      </div>
    );
  }
}

export const BirthdayPage = withRouter(NCBirthday);
