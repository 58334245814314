import React from "react";
import ApiService from "../utils/apiService";
import { Dropdown, Segment } from "semantic-ui-react";
import { SpeedVsAccuracy } from "../components/SpeedVsAccuracy";
import axios from "axios";

const CancelToken = axios.CancelToken;

const options = [
  { key: "All", text: "All Shootings", value: "all" },
  { key: "Prone", text: "Prone shootings", value: "prone" },
  { key: "Standing", text: "Standing shootings", value: "standing" },
];

export class SpeedVsAccuracyContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      data: [],
      threshold: 0,
      selected_shooting: "all",
    };
  }

  handleFilteredShootings = (event, data) => {
    this.setState({ selected_shooting: data.value });
    this.fetchDatas(
      this.props.gender,
      this.props.season,
      data.value,
      this.state.source
    );
  };

  isEmptyData(datas) {
    let is_empty = true;
    for (let data of datas) {
      if (data.data.length > 0) {
        is_empty = false;
      }
    }
    return is_empty;
  }

  filter_datas(data, filters, filter_countries) {
    let newdata = data;
    if (filters.length > 0 || filter_countries.length > 0) {
      newdata = data.filter(
        (data) =>
          filters.includes(data.id) ||
          filter_countries.includes(data.nationality)
      );
    } else {
      newdata = data.slice(0, 5);
    }
    return newdata;
  }

  fetchDatas = async (gender, season, shootingtype, source) => {
    this.setState({ loading: true });
    try {
      const { data } = await ApiService.getSpeedVsAccuracy(
        this.props.season,
        this.props.gender,
        shootingtype,
        source
      );
      if (gender === this.props.gender && season === this.props.season) {
        this.setState({ data: data });
      }
    } catch (error) {
      if (
        gender === this.props.gender &&
        season === this.props.season &&
        error.message !== "gender change"
      ) {
        this.setState({ error: true });
      }
    }
    if (gender === this.props.gender && season === this.props.season) {
      this.setState({ loading: false });
    }
  };

  async componentDidMount() {
    const newSource = CancelToken.source();
    this.setState({ source: newSource });
    this.fetchDatas(
      this.props.gender,
      this.props.season,
      this.state.selected_shooting,
      newSource
    );
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.props.season !== prevProps.season) {
      ApiService.cancelFetch(this.state.source);

      const newSource = CancelToken.source();
      this.setState({ data: [], source: newSource, filtered_dropped: "" });
      this.fetchDatas(
        this.props.gender,
        this.props.season,
        this.state.selected_shooting,
        newSource
      );
    } else {
      if (
        this.props.filtered !== prevProps.filtered ||
        this.props.filtered_countries !== prevProps.filtered_countries
      ) {
        ApiService.cancelFetch(this.state.source);

        const newSource = CancelToken.source();
        this.setState({ data: [], source: newSource });
        this.fetchDatas(
          this.props.gender,
          this.props.season,
          this.state.selected_shooting,
          newSource
        );
      }
    }
  }

  render() {
    return (
      <>
        <Segment className="noborder">
          <Dropdown
            placeholder="All shootings"
            fluid
            selection
            value={this.state.selected_discipline}
            options={options}
            disabled={this.props.loading}
            onChange={this.handleFilteredShootings}
          />
        </Segment>
        <SpeedVsAccuracy
          data={this.filter_datas(
            this.state.data,
            this.props.filtered,
            this.props.filtered_countries
          )}
          loading={this.state.loading}
          error={this.state.error}
          empty_datas={this.isEmptyData(
            this.filter_datas(
              this.state.data,
              this.props.filtered,
              this.props.filtered_countries
            )
          )}
          threshold={this.state.threshold}
          show_legend={true}
        />
      </>
    );
  }
}
