import React from "react";
import { Table, Header, Loader, Progress, Message } from "semantic-ui-react";
import { COUNTRY_CODES_CONVERSION } from "../utils/constants";

export class ShotsAccuracy extends React.PureComponent {
  render() {
    if (this.props.error) {
      return (
        <Message
          negative
          className="text_center"
          content="Sorry, an error occured"
        ></Message>
      );
    }
    if (this.props.loading) {
      return (
        <div className="LoaderContainer">
          <Loader active content="Loading"></Loader>
        </div>
      );
    }
    if (this.props.data.length === 0) {
      return (
        <Message className="text_center" content="No data to display"></Message>
      );
    }
    return (
      <Table striped className="shotsaccuracy_results_table">
        <Table.Body>
          {this.props.data.map((athlete, index) => (
            <Table.Row
              key={athlete.id}
              onClick={() => this.props.showAthleteCard(true, athlete.id)}
              className="athlete_clickable"
            >
              <Table.Cell>
                <span
                  className={
                    athlete.ranking === 1
                      ? `score_place score_place--grey`
                      : "score_place"
                  }
                >
                  {athlete.ranking}
                </span>
              </Table.Cell>
              <Table.Cell>
                {COUNTRY_CODES_CONVERSION[athlete.nationality] && (
                  <img
                    src={`static/img/${
                      COUNTRY_CODES_CONVERSION[athlete.nationality]["code"]
                    }.png`}
                    alt={COUNTRY_CODES_CONVERSION[athlete.nationality]["name"]}
                  />
                )}
              </Table.Cell>
              {/*<Table.Cell>
                {athlete.photo ? (
                  <div
                    className={
                      athlete.ranking === 1
                        ? `athlete_picture-container athlete_picture-container--grey`
                        : "athlete_picture-container"
                    }
                  >
                    <img
                      className="athlete_picture"
                      alt={athlete.family_name}
                      src={athlete.photo}
                      title={athlete.family_name + " " + athlete.given_name}
                    ></img>
                  </div>
                ) : (
                  <div className="athlete_picture-container athlete_picture-container--empty">
                    <span className="athlete_picture"></span>
                  </div>
                )}
                </Table.Cell>*/}
              <Table.Cell>
                <Header as="h3">
                  {athlete.family_name} {athlete.given_name}
                  {athlete.isu25 && <span className="u25_span">U25</span>}
                </Header>
              </Table.Cell>
              <Table.Cell className="progress_bar-shooting">
                <Progress
                  percent={athlete.shots_accuracy}
                  indicating
                  size="small"
                />
                {athlete.good_shots}/{athlete.total_shots}
              </Table.Cell>
              <Table.Cell>
                <span className="score_points_time">
                  {athlete.shots_accuracy}%
                </span>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }
}
